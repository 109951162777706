import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

export function SearchReset() {
  const [parameters, setParameters] = useSearchParams();

  function reset() {
    parameters.delete('s');
    parameters.delete('d');
    setParameters(parameters);
  }

  const showReset = parameters.get('s')?.length > 0 ? true : false;

  return (
    <React.Fragment>
      {showReset && (
        <Row className='mb-3'>
          <Col xs={12} className='text-center'>
            <Button variant='warning' onClick={reset}>
              Reset Search
            </Button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}
