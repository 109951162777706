import * as React from 'react';
import { Form } from 'react-bootstrap';

import { CompanyForm, ContactForm, ProjectForm } from '@/components/forms';
import { ModalForm } from '@/components/modal-form';
import { SelectorAPI } from '@/components/selector-api';
import { useStore } from '@/store';

interface Properties {
  elementSelect?: any;
  setElementSelect: (argument: object) => void;
  saveForm: () => void;
  modal: React.MutableRefObject<any>;
}
export const SelectorAPIHelper = React.forwardRef(function APIGroupHelper(
  { elementSelect, setElementSelect, saveForm, modal }: Properties,
  reference
) {
  const { response, setResponse } = useStore();

  const modalCompany = React.useRef(null);
  const modalContact = React.useRef(null);
  const modalContactLink = React.useRef(null);
  const modalProject = React.useRef(null);

  React.useImperativeHandle(reference, () => ({
    addCompany() {
      saveForm();
      setResponse();
      modal.current.closeModal();
      modalCompany.current.openModal();
    },
    addContact() {
      saveForm();
      setResponse();
      modal.current.closeModal();
      modalContact.current.openModal();
    },
    linkContact() {
      saveForm();
      setResponse();
      modal.current.closeModal();
      modalContactLink.current.openModal();
    },
    addProject() {
      saveForm();
      setResponse();
      modal.current.closeModal();
      modalProject.current.openModal();
    }
  }));

  function openSession() {
    if (response?.message == 'Company created successfully') {
      setElementSelect({ ...elementSelect, companyId: response?.data, tokenId: response?.data });
    } else if (
      response?.message == 'Contact created successfully' ||
      response?.message == 'Contact added to company successfully'
    ) {
      setElementSelect({ ...elementSelect, contactId: response?.data, tokenId: response?.data });
    } else if (response?.message == 'Project created successfully') {
      setElementSelect({ ...elementSelect, projectId: response?.data });
    }
    modal.current.openModal();
  }

  return (
    <React.Fragment>
      <ModalForm title='Add Company' size='lg' url='/companies' ref={modalCompany} onClose={openSession} draggable>
        <CompanyForm />
      </ModalForm>

      <ModalForm title='Add Contact' size='lg' url='/contacts' ref={modalContact} onClose={openSession} draggable>
        <Form.Control type='hidden' name='companyId' value={elementSelect?.companyId} />
        <ContactForm />
      </ModalForm>

      <ModalForm
        title='Link Contact'
        url='/companies/contact'
        putId={elementSelect?.companyId}
        ref={modalContactLink}
        onClose={openSession}
        draggable
      >
        <SelectorAPI name='contact' fetchURL={'/companies/contact/' + elementSelect?.companyId} />
      </ModalForm>

      <ModalForm title='Add Project' url='/projects' ref={modalProject} onClose={openSession} draggable>
        <ProjectForm companyId={elementSelect?.companyId} />
      </ModalForm>
    </React.Fragment>
  );
});
