import * as React from 'react';
import { Table } from 'react-bootstrap';
import { useLoaderData } from 'react-router-dom';

import { ListLink } from '@/components/listlink';
import { Pager } from '@/components/pager';
import { SearchReset } from '@/components/search-reset';
import { Sorter } from '@/components/sorter';
import { getList } from '@/helper/apicalls';
import { JobListResponse } from '@/helper/interfaces';

import { baseURL, settings } from './const';

export async function listLoader({ request }: any) {
  const parameters = new URL(request.url).searchParams;
  return getList(baseURL, parameters, settings);
}

export function List() {
  const { data, currentPage, numberOfPages, orderBy, order } = useLoaderData() as JobListResponse;
  return (
    <React.Fragment>
      <Table striped bordered hover size='sm'>
        <thead>
          <tr>
            <Sorter name='id' orderBy={orderBy} order={order}>
              Job &#35;
            </Sorter>
            <Sorter name='company' orderBy={orderBy} order={order} />
            <Sorter name='project' orderBy={orderBy} order={order} />
            <Sorter name='contact' orderBy={orderBy} order={order} />
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data?.map((d) => {
              const company = d.company;
              const contact = d.contact;
              const project = d.project;
              return (
                <tr key={d.id}>
                  <ListLink to={'/jobs/' + d.id}>Job {d.id}</ListLink>
                  <ListLink to={'/companies/' + company?.id}>{company?.name}</ListLink>
                  <ListLink to={'/projects/' + project?.id}>{project?.name}</ListLink>
                  <ListLink to={'/contacts/' + contact?.id}>{contact?.name}</ListLink>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={4}>None</td>
            </tr>
          )}
        </tbody>
      </Table>
      <SearchReset />
      <Pager currentPage={currentPage} numberOfPages={numberOfPages} />
    </React.Fragment>
  );
}
