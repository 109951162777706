import { deleteSingle, postSingle, putSingle } from '@/helper/apicalls';

import { baseURL } from './const';

// eslint-disable-next-line sonarjs/cognitive-complexity
export async function singleAction({ request, params }: any) {
  const body = Object.fromEntries(await request.formData());
  const redirectTo = body.redirectTo;
  delete body.redirectTo;

  if (body.companyId) body.companyId = JSON.parse(body.companyId);
  if (body.contactId) body.contactId = JSON.parse(body.contactId);
  if (body.projectId) body.projectId = JSON.parse(body.projectId);
  if (body.mixerId) body.mixerId = JSON.parse(body.mixerId);
  if (body.assistantId) body.assistantId = JSON.parse(body.assistantId);
  if (body.prePadHours) body.prePadHours = JSON.parse(body.prePadHours);
  if (body.postPadHours) body.postPadHours = JSON.parse(body.postPadHours);
  if (body.roomId) body.roomId = JSON.parse(body.roomId);

  body.unsupervised = body.unsupervised == 'on' ? true : false;
  body.noCharge = body.noCharge == 'on' ? true : false;

  if (request.method == 'POST') {
    return postSingle(baseURL, body);
  } else if (request.method == 'PUT') {
    return putSingle(baseURL, params.id, body);
  } else if (request.method == 'DELETE') {
    return deleteSingle(baseURL, params.id, redirectTo);
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}
