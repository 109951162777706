/* eslint-disable security/detect-object-injection */
import * as React from 'react';
import { Form } from 'react-bootstrap';

import { FormContext } from '@/helper/context';
import { camelToTitle } from '@/helper/utility';

interface Properties {
  name: string;
  value?: string;
  valueSelect?: string;
}

export function SelectorPad({ name, value, valueSelect }: Properties) {
  const displayName = camelToTitle(name);
  const { changeTracker, setWasChanged, disabled, errors } = React.useContext(FormContext);
  const [valueState, setValueState] = React.useState(valueSelect ?? value);

  React.useEffect(() => {
    const didChange = valueSelect ? (valueSelect == value ? false : true) : false;
    changeTracker.current = {
      ...changeTracker.current,
      [name]: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }, []);

  function onChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setValueState(event.target.value);

    const didChange = event.target.value == value ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      [name]: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }

  return (
    <Form.Group controlId={name} className='mt-3 col-6'>
      <Form.Label>{displayName}</Form.Label>
      <Form.Select
        name={name}
        value={valueState}
        disabled={disabled}
        onChange={onChange}
        isInvalid={errors[name] ? true : false}
      >
        <option value='0'></option>
        <option value='0.25'>0.25</option>
        <option value='0.5'>0.50</option>
        <option value='0.75'>0.75</option>
        <option value='1'>1.00</option>
        <option value='1.25'>1.25</option>
        <option value='1.5'>1.50</option>
        <option value='1.75'>1.75</option>
        <option value='2'>2.00</option>
      </Form.Select>
      <Form.Control.Feedback type='invalid'>{errors[name]}</Form.Control.Feedback>
    </Form.Group>
  );
}
