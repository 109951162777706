import * as React from 'react';
import { Container, Nav } from 'react-bootstrap';
import { Outlet, useMatches } from 'react-router-dom';

import { ContactForm } from '@/components/forms';
import { ModalForm } from '@/components/modal-form';
import { PageHeader } from '@/components/pageheader';
import { SearchBar } from '@/components/search-bar';
import { Match } from '@/helper/interfaces';
import { useStore } from '@/store';

import { baseURL } from './const';

export function Menu() {
  const { user } = useStore();

  const matches = useMatches() as Match[];
  const isList = matches.at(-1).data?.isList;

  const crumbs = matches
    .filter((match: any) => {
      return Boolean(match.handle?.crumb);
    })
    .map((match: any) => {
      return { name: match.handle?.crumb, link: match.pathname };
    });

  const modal = React.useRef(null);

  return (
    <React.Fragment>
      <Container fluid={isList ? true : false}>
        {isList ? (
          <React.Fragment>
            <PageHeader crumbs={crumbs}>Contacts</PageHeader>
            <Nav>
              {user?.role?.editContacts && <Nav.Link onClick={() => modal.current.openModal()}>Add Contact</Nav.Link>}
              <SearchBar />
            </Nav>
          </React.Fragment>
        ) : (
          <PageHeader crumbs={crumbs}>Edit Contact</PageHeader>
        )}
        <Outlet />
      </Container>

      <ModalForm title='Add Contact' size='lg' url={baseURL} redirect={baseURL} ref={modal} draggable>
        <ContactForm />
      </ModalForm>
    </React.Fragment>
  );
}
