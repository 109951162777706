import { addMinutes, parseISO } from 'date-fns';
import * as React from 'react';
import { Button, Card, Form, Table } from 'react-bootstrap';
import { PencilSquare, Trash3 } from 'react-bootstrap-icons';

import { Input } from '@/components/input';
import { InputNumber } from '@/components/input-number';
import { InputTimeDuration } from '@/components/input-timeduration';
import { ModalDelete } from '@/components/modal-delete';
import { ModalForm } from '@/components/modal-form';
import { SelectorAPISimple } from '@/components/selector-api-simple';
import { SelectorTime } from '@/components/selector-time';
import { Switch } from '@/components/switch';
import { Service, ServiceType, User } from '@/helper/interfaces';
import { formatMoney, formatTime } from '@/helper/utility';
import { useStore } from '@/store';

interface Properties {
  baseURL: string;
  id: number;
  services: Service[];
  total: number;
  mixer?: User;
  initStartTime?: string;
  disabled: boolean;
  selectMixer?: boolean;
}

export function Services({
  baseURL,
  id,
  services,
  total,
  mixer,
  initStartTime,
  disabled,
  selectMixer = true
}: Properties) {
  const { user } = useStore();

  const [editService, setEditService] = React.useState<Service>();
  const [editType, setEditType] = React.useState<ServiceType>();
  const [editMixer, setEditMixer] = React.useState<User>(mixer);
  const [isNew, setIsNew] = React.useState<boolean>();
  const [rate, setRate] = React.useState<string>();
  const [customRate, setCustomRate] = React.useState<boolean>();

  const [startTime, setStartTime] = React.useState('');
  const [endTime, setEndTime] = React.useState('');
  const [duration, setDuration] = React.useState<number>();

  function openModal(service?: Service) {
    if (service) {
      setEditService(service);
      setCustomRate(service.customRate);
      setRate(service.customRate ? service.regularRate.toString() : '');
      setEditType(service.type);
      setIsNew(false);
      if (initStartTime) {
        setStartTime(service.startTime);
        setEndTime(service.endTime);
        setEditMixer(service.mixer);
      } else {
        setDuration(service.regularDuration);
      }
    } else {
      // eslint-disable-next-line unicorn/no-useless-undefined
      setEditService(undefined);
      setCustomRate(false);
      setRate('');
      setIsNew(true);
      setEditMixer(mixer);
      if (initStartTime) {
        if (services.length > 0) {
          const lastEndTime = services.at(-1).endTime;
          setStartTime(lastEndTime);
          setEndTime(addMinutes(parseISO(lastEndTime), 15).toString());
        } else {
          setStartTime(initStartTime);
          setEndTime(addMinutes(parseISO(initStartTime), 15).toString());
        }
      }
    }
    modal.current.openModal();
  }
  function openModalDelete(service: Service) {
    setEditService(service);
    modalDelete.current.openModal();
  }

  const modal = React.useRef(null);
  const modalDelete = React.useRef(null);

  return (
    <React.Fragment>
      <Card className='mt-3'>
        <Card.Body>
          <Card.Title className='clearfix'>
            Audio Services
            <Button type='button' size='sm' className='float-end' onClick={() => openModal()} disabled={disabled}>
              + Add
            </Button>
          </Card.Title>
          <Table striped size='sm'>
            <thead>
              <tr>
                <th>Type</th>
                <th>Description</th>
                <th>Time</th>
                {user?.role?.managePrices && (
                  <React.Fragment>
                    <th>Rate</th>
                    <th>Subtotal</th>
                  </React.Fragment>
                )}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {services.length === 0 && (
                <tr>
                  <td colSpan={6}>None</td>
                </tr>
              )}

              {/* display for work orders */}
              {initStartTime && (
                <React.Fragment>
                  {services.map((service) => {
                    const showMixer = service.mixer?.id == mixer?.id ? false : true;
                    const durationUnit = service.regularDuration <= 1 ? 'hr' : 'hrs';
                    const overTimeDurationUnit = service.overTimeDuration <= 1 ? 'hr' : 'hrs';
                    const doubleTimeDurationUnit = service.doubleTimeDuration <= 1 ? 'hr' : 'hrs';
                    return (
                      <tr key={service.id}>
                        <td>
                          {service?.type?.name}
                          <small>{service?.mixer && showMixer ? ' (' + service?.mixer?.name + ')' : ''}</small>
                        </td>
                        <td>{service.description}</td>
                        <td>
                          {formatTime(service.startTime)}&nbsp;-&nbsp;
                          {formatTime(service.endTime)}
                          {service.overTimeDuration === 0 && (
                            <small>
                              &nbsp;({service.regularDuration} {durationUnit})
                            </small>
                          )}
                          {service.overTimeDuration > 0 && (
                            <React.Fragment>
                              <br />
                              <small className='ms-4'>
                                Regular Time: {service.regularDuration} {durationUnit}
                              </small>
                              <br />
                              <small className='ms-4'>
                                Over Time: {service.overTimeDuration} {overTimeDurationUnit}
                              </small>
                            </React.Fragment>
                          )}
                          {service.doubleTimeDuration > 0 && (
                            <React.Fragment>
                              <br />
                              <small className='ms-4'>
                                Double Time: {service.doubleTimeDuration} {doubleTimeDurationUnit}
                              </small>
                            </React.Fragment>
                          )}
                        </td>
                        {user?.role?.managePrices && (
                          <React.Fragment>
                            <td>
                              {formatMoney(service.regularRate)}
                              {service.overTimeDuration > 0 && (
                                <React.Fragment>
                                  <br />
                                  <small className='ms-4'>{formatMoney(service.regularSubtotal)}</small>
                                  <br />
                                  <small className='ms-4'>{formatMoney(service.overTimeSubtotal)}</small>
                                </React.Fragment>
                              )}
                              {service.doubleTimeDuration > 0 && (
                                <React.Fragment>
                                  <br />
                                  <small className='ms-4'>{formatMoney(service.doubleTimeSubtotal)}</small>
                                </React.Fragment>
                              )}
                            </td>
                            <td>{formatMoney(service.subtotal)}</td>
                          </React.Fragment>
                        )}
                        <td className='text-end'>
                          <React.Fragment>
                            <Button
                              variant='link'
                              className='btn-icon'
                              onClick={() => openModal(service)}
                              disabled={disabled}
                            >
                              <PencilSquare aria-label='edit' />
                            </Button>
                            <Button
                              variant='link'
                              className='btn-icon'
                              onClick={() => openModalDelete(service)}
                              disabled={disabled}
                            >
                              <Trash3 area-label='delete' />
                            </Button>
                          </React.Fragment>
                        </td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              )}

              {/* display for estimates */}
              {!initStartTime && (
                <React.Fragment>
                  {services.map((service) => {
                    const durationUnit = service.regularDuration <= 1 ? 'hr' : 'hrs';
                    return (
                      <tr key={service.id}>
                        <td>{service.type.name}</td>
                        <td>{service.description}</td>
                        <td>
                          {service.regularDuration} {durationUnit}
                        </td>
                        <td>{formatMoney(service.regularRate)}</td>
                        <td>{formatMoney(service.subtotal)}</td>
                        <td className='text-end'>
                          <React.Fragment>
                            <Button
                              variant='link'
                              className='btn-icon'
                              onClick={() => openModal(service)}
                              disabled={disabled}
                            >
                              <PencilSquare aria-label='edit' />
                            </Button>
                            <Button
                              variant='link'
                              className='btn-icon'
                              onClick={() => openModalDelete(service)}
                              disabled={disabled}
                            >
                              <Trash3 area-label='delete' />
                            </Button>
                          </React.Fragment>
                        </td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              )}
            </tbody>
          </Table>
          {user?.role?.managePrices && (
            <p className='text-end'>
              <b>Audio Services Total:</b> {formatMoney(total)}
            </p>
          )}
        </Card.Body>
      </Card>

      <ModalForm
        title={(isNew ? 'Add' : 'Edit') + ' Audio Service'}
        url={baseURL + '/service'}
        putId={editService?.id}
        ref={modal}
        draggable
      >
        <Form.Control type='hidden' name='id' value={id} />
        {initStartTime && <SelectorTime value={{ startTime: startTime, endTime: endTime }} />}

        <SelectorAPISimple
          name='type'
          value={editService?.type?.id}
          fetchURL='/workorders/service/types'
          setValue={setEditType}
        />

        {user?.role?.managePrices && (
          <React.Fragment>
            <Switch name='customRate' value={customRate} setValue={setCustomRate} />
            {customRate && <InputNumber name='rate' value={rate} money />}
          </React.Fragment>
        )}

        {selectMixer && editType?.hasMixer && (
          <SelectorAPISimple name='mixer' value={editMixer?.id} fetchURL='/users/mixers' />
        )}

        {!initStartTime && <InputTimeDuration name='duration' size='col-12' value={duration} />}
        <Input name='description' size='col-12' value={editService?.description} />
      </ModalForm>

      <ModalDelete title='Delete Audio Service' url={baseURL + '/service'} deleteId={editService?.id} ref={modalDelete}>
        Are you sure you want to delete this service?
      </ModalDelete>
    </React.Fragment>
  );
}
