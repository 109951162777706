import * as React from 'react';
import { Alert, Button, Form, Modal, Row, Spinner } from 'react-bootstrap';
import Draggable from 'react-draggable';
import { useFetcher, useNavigate } from 'react-router-dom';

import { FormContext } from '@/helper/context';
import { formatErrors } from '@/helper/utility';
import { useStore } from '@/store';

interface Properties {
  title: string;
  url: string;
  putId?: number;
  redirect?: string;
  size?: 'sm' | 'lg' | 'xl';
  disabled?: boolean;
  noDisableForm?: boolean;
  noChanges?: boolean;
  onClose?: () => void;
  reset?: () => void;
  draggable?: boolean;
  children: React.ReactNode;
}

export const ModalForm = React.forwardRef(function ModalForm(
  {
    title,
    url,
    putId,
    redirect,
    size,
    disabled,
    noDisableForm = false,
    noChanges = false,
    onClose,
    reset,
    draggable = false,
    children
  }: Properties,
  reference
) {
  const [errors, setErrors] = React.useState<any>({});
  const [message, setMessage] = React.useState('');

  const { setResponse } = useStore();

  const fetcher = useFetcher();
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (fetcher.state == 'loading' || fetcher.state == 'submitting') {
      setIsLoading(true);
    } else {
      if (fetcher.data?.status == 200) {
        setResponse(fetcher.data.data);
        setModal(false);
        if (redirect) {
          navigate(redirect + '/' + fetcher.data.data.data);
        } else if (typeof reset === 'function') {
          reset();
        }
      } else {
        if (typeof fetcher.data === 'object') {
          setErrors(formatErrors(fetcher.data));
        } else if (typeof fetcher.data === 'string') {
          setMessage(fetcher.data);
        }
        setIsLoading(false);
      }
    }
  }, [fetcher]);

  const [modal, setModal] = React.useState(false);
  React.useImperativeHandle(reference, () => ({
    openModal() {
      setModal(true);
      setIsLoading(false);
      setErrors({});
    },
    closeModal() {
      if (!isLoading) {
        setModal(false);
      }
    }
  }));
  const closeModal = () => {
    if (!isLoading) {
      setModal(false);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    fetcher.submit(formData, {
      method: putId ? 'put' : 'post',
      action: url + (putId ? '/' + putId : '')
    });
  };

  const position = React.useRef({ x: 0, y: 0 });
  function handleStop(event: any, data: any) {
    event.preventDefault();
    position.current = { x: data.x, y: data.y };
  }

  const nodeReference = React.useRef(null);

  return (
    <Modal show={modal} onHide={closeModal} size={size} contentClassName='hide' onExiting={onClose}>
      <Draggable
        handle='.modal-header'
        nodeRef={nodeReference}
        onStop={handleStop}
        bounds={{ top: -50 }}
        defaultPosition={{ x: position.current.x, y: position.current.y }}
        disabled={!draggable}
      >
        <div ref={nodeReference} className={'modal-content' + (draggable ? ' draggable' : '')}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <ModalInner
            isLoading={isLoading}
            disabled={disabled}
            noDisableForm={noDisableForm}
            noChanges={noChanges}
            errors={errors}
            handleSubmit={handleSubmit}
            closeModal={closeModal}
            message={message}
            url={url}
          >
            {children}
          </ModalInner>
        </div>
      </Draggable>
    </Modal>
  );
});

interface InnerProperties {
  isLoading: boolean;
  disabled: boolean;
  noDisableForm: boolean;
  noChanges: boolean;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  errors: any;
  closeModal: () => void;
  message: string;
  url: string;
  children: React.ReactNode;
}

function ModalInner({
  isLoading,
  disabled,
  noDisableForm,
  noChanges,
  errors,
  handleSubmit,
  closeModal,
  message,
  url,
  children
}: InnerProperties) {
  const changeTracker = React.useRef({});
  const [wasChanged, setWasChanged] = React.useState(false);

  return (
    <React.Fragment>
      <Modal.Body>
        <FormContext.Provider
          value={{ changeTracker, setWasChanged, disabled: isLoading || (!noDisableForm && disabled), errors }}
        >
          <Form id={'form-' + url} onSubmit={handleSubmit} noValidate>
            <Row>
              {message?.length > 0 && <Alert variant='danger'>{message}</Alert>}
              {children}
            </Row>
          </Form>
        </FormContext.Provider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' type='button' className='me-2' onClick={closeModal} disabled={isLoading}>
          Cancel
        </Button>
        {isLoading ? (
          <Button variant='primary' type='submit' disabled={true}>
            <Spinner as='span' size='sm' role='status' aria-hidden='true' />
            &nbsp;Loading...
          </Button>
        ) : (
          <Button
            variant='primary'
            type='submit'
            form={'form-' + url}
            disabled={disabled || (!wasChanged && !noChanges) || message?.length > 0}
          >
            Save
          </Button>
        )}
      </Modal.Footer>
    </React.Fragment>
  );
}
