import { addDays, format, parseISO, startOfDay, subDays } from 'date-fns';
import * as React from 'react';
import { Form, Pagination } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useSearchParams } from 'react-router-dom';

import { CustomInput } from './datepicker-custom';

export function SearchBarDate() {
  const [parameters, setParameters] = useSearchParams();

  const date = parameters.get('d') ? startOfDay(parseISO(parameters.get('d'))) : startOfDay(new Date());
  const dateFormat = 'yyyy-MM-dd';

  function changeDate(newDate: Date) {
    parameters.set('d', format(newDate, dateFormat));
    setParameters(parameters);
  }
  function previousDay() {
    parameters.set('d', format(subDays(date, 1), dateFormat));
    setParameters(parameters);
  }
  function nextDay() {
    parameters.set('d', format(addDays(date, 1), dateFormat));
    setParameters(parameters);
  }

  return (
    <React.Fragment>
      <Form noValidate className='ms-auto mb-2'>
        <Form.Group controlId='date' className='me-2'>
          <DatePicker
            selected={date}
            onChange={(newDate) => changeDate(newDate)}
            className='form-control'
            dateFormat='EEEE, MMMM do, yyyy'
            todayButton='Today'
            customInput={<CustomInput />}
          />
        </Form.Group>
      </Form>
      <Pagination className='mb-1'>
        <Pagination.Prev onClick={previousDay} />
        <Pagination.Next onClick={nextDay} />
      </Pagination>
    </React.Fragment>
  );
}
