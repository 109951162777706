import * as React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useRouteError, useSearchParams } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError() as any;
  const [parameters, setParameters] = useSearchParams();

  function reset() {
    parameters.delete('s');
    parameters.delete('d');
    setParameters(parameters);
  }

  return (
    <Row className='mt-5'>
      <Col className='ms-auto me-auto'>
        <Alert variant='danger'>
          {error.statusText || error.message}
          <br />
          {parameters.get('s')?.length > 0 && <Alert.Link onClick={reset}>Reset Search</Alert.Link>}
          {parameters.get('d')?.length > 0 && <Alert.Link onClick={reset}>Go To Today</Alert.Link>}
        </Alert>
      </Col>
    </Row>
  );
}
