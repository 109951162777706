import * as React from 'react';
import { Table } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

interface Properties {
  elements: Element[];
  hideEmpty?: boolean;
}
interface Element {
  title: string;
  content?: string;
  subContent?: Element[];
  link?: string;
}

export function InfoTable({ elements, hideEmpty = false }: Properties) {
  const location = useLocation();
  const from = location.pathname + location.search;

  return (
    <Table className='table-list'>
      <tbody>
        {elements.map((element, index) => (
          <React.Fragment key={index}>
            {(!hideEmpty || element.content?.length > 0 || element.subContent?.length > 0) && (
              <tr>
                <td className='sm-col ps-3'>
                  <b>{element.title}:</b>
                </td>
                <td className='prewrap'>
                  {element.link && (
                    <Link to={element.link} state={{ from: from }}>
                      {element.content}
                    </Link>
                  )}
                  {!element.link && (
                    <React.Fragment>
                      {element.content}
                      {element.subContent?.map((subElement, index) => (
                        <React.Fragment key={index}>
                          {subElement.content.length > 0 && (
                            <p>
                              <b>{subElement.title}:</b> {subElement.content}
                            </p>
                          )}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  )}
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
}
