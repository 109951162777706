import * as React from 'react';
import { Alert, Button, Card, Table } from 'react-bootstrap';
import { PencilSquare, Trash3 } from 'react-bootstrap-icons';
import { Link, useLoaderData, useLocation } from 'react-router-dom';

import { CustomPrices } from '@/components/customprices';
import { FormButtons } from '@/components/formbuttons';
import { CompanyForm, ProjectForm } from '@/components/forms';
import { InfoTable } from '@/components/infotable';
import { InputTokens } from '@/components/input-tokens';
import { ModalDelete } from '@/components/modal-delete';
import { ModalForm } from '@/components/modal-form';
import { deleteSingle, getSingle, postSingle, putSingle } from '@/helper/apicalls';
import { Company, Project } from '@/helper/interfaces';
import { formatAddress, formatPhone } from '@/helper/utility';
import { useStore } from '@/store';

import { baseURL } from './const';

export async function singleLoader({ params }: any) {
  if (Number.isInteger(Number.parseInt(params.id, 10))) {
    return getSingle(baseURL, params.id);
  } else {
    throw new TypeError('404! This is not a valid URL.');
  }
}

export async function singleAction({ request, params }: any) {
  const body = Object.fromEntries(await request.formData());
  const redirectTo = body.redirectTo;
  delete body.redirectTo;

  if (request.method == 'POST') {
    return postSingle(baseURL, body);
  } else if (request.method == 'PUT') {
    return putSingle(baseURL, params.id, body);
  } else if (request.method == 'DELETE') {
    return deleteSingle(baseURL, params.id, redirectTo);
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}

export function Single() {
  const { user } = useStore();
  const data = useLoaderData() as Company;

  const location = useLocation();
  const [redirectTo] = React.useState(location.state?.from || baseURL);
  const from = location.pathname + location.search;

  const modal = React.useRef(null);
  const modalProject = React.useRef(null);
  const modalProjectDelete = React.useRef(null);

  const disabled = data.deleted || !user?.role?.editCompanies;

  const [projectToEdit, setProjectToEdit] = React.useState<Project>();
  const [projectDeleteId, setProjectDeleteId] = React.useState<number>();
  function newProject() {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setProjectToEdit(undefined);
    modalProject.current.openModal();
  }
  function editProject(id: number) {
    setProjectToEdit(data.projects.find((project) => project.id === id));
    modalProject.current.openModal();
  }
  function deleteProject(id: number) {
    setProjectDeleteId(id);
    modalProjectDelete.current.openModal();
  }

  return (
    <React.Fragment>
      {data.deleted && (
        <Alert variant='danger' className='mt-3'>
          This company was deleted and can no longer be edited.
        </Alert>
      )}
      {!user?.role?.editCompanies && !data.deleted && (
        <Alert variant='warning' className='mt-3'>
          You are not authorized to edit this page.
        </Alert>
      )}
      <Card>
        <Card.Body>
          <Card.Title className='clearfix'>
            Company Info
            <Button
              type='button'
              size='sm'
              className='float-end'
              onClick={() => modal.current.openModal()}
              disabled={disabled}
            >
              <PencilSquare className='icon-align' /> Edit
            </Button>
          </Card.Title>
          <InfoTable
            elements={[
              { title: 'Name', content: data.name },
              { title: 'Email', content: data.email },
              { title: 'Phone', content: formatPhone(data.phone) },
              {
                title: 'Address',
                content: formatAddress(data.address, data.city, data.state, data.zip, data.country)
              },
              { title: 'Notes', content: data.notes }
            ]}
          />
        </Card.Body>
      </Card>

      <Card className='mt-3'>
        <Card.Body>
          <Card.Title className='clearfix'>Contacts</Card.Title>
          <InputTokens
            name='contact'
            fetchURL={baseURL + '/contact/' + data.id}
            baseURL={baseURL + '/contact'}
            linkURL='/contacts'
            id={data.id}
            value={data.contacts}
            disabled={disabled}
          />
        </Card.Body>
      </Card>

      <Card className='mt-3'>
        <Card.Body>
          <Card.Title className='clearfix'>
            Projects
            <Button type='button' size='sm' className='float-end' onClick={newProject} disabled={disabled}>
              + Add
            </Button>
          </Card.Title>
          <Table striped hover size='sm' className='mt-2'>
            <tbody>
              {data.projects.length === 0 && (
                <tr>
                  <td>None</td>
                  <td></td>
                </tr>
              )}
              {data.projects.map((project) => (
                <tr key={project.id}>
                  <td>
                    <Link to={'/projects/' + project.id} state={{ from: from }}>
                      {project.name}
                    </Link>
                  </td>
                  <td className='text-end'>
                    <Button
                      variant='link'
                      className='btn-icon me-1'
                      onClick={() => editProject(project.id)}
                      disabled={disabled}
                    >
                      <PencilSquare aria-label='edit' />
                    </Button>
                    <Button
                      variant='link'
                      className='btn-icon'
                      onClick={() => deleteProject(project.id)}
                      disabled={disabled}
                    >
                      <Trash3 aria-label='delete' />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {user?.role?.managePrices && (
        <CustomPrices
          id={data.id}
          value={{ expenses: data.expenses, services: data.services, stock: data.stock }}
          baseURL={baseURL}
          disabled={disabled}
        />
      )}

      <FormButtons type='Company' redirectTo={redirectTo} showDelete={!disabled}>
        <React.Fragment>
          Are you sure you want to delete {data.name}?<br />
          {data.projects?.length > 0 && (
            <React.Fragment>All projects for this company will also be deleted.</React.Fragment>
          )}
        </React.Fragment>
      </FormButtons>

      <ModalForm title='Edit Info' size='lg' url={baseURL} putId={data.id} ref={modal} draggable>
        <CompanyForm data={data} />
      </ModalForm>

      <ModalForm
        title='Add Project'
        url='/projects'
        putId={projectToEdit ? projectToEdit.id : undefined}
        ref={modalProject}
        draggable
      >
        <ProjectForm data={projectToEdit} companyId={data.id} />
      </ModalForm>

      <ModalDelete title='Delete Project' url='/projects' ref={modalProjectDelete} deleteId={projectDeleteId}>
        Are you sure you want to delete this project?
      </ModalDelete>
    </React.Fragment>
  );
}
