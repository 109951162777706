import * as React from 'react';
import { Breadcrumb } from 'react-bootstrap';

interface Properties {
  crumbs?: Crumb[];
  children: React.ReactNode;
}
interface Crumb {
  name: string;
  link: string;
}

export function PageHeader({ crumbs, children }: Properties) {
  return (
    <React.Fragment>
      <h1 className='display-6 mt-3'>{children}</h1>
      {crumbs?.length > 1 && (
        <Breadcrumb>
          {crumbs?.map((crumb, index) => (
            <Breadcrumb.Item key={index} href={crumb.link} active={index == crumbs.length - 1 ? true : false}>
              {crumb.name}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}
    </React.Fragment>
  );
}
