import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import '@/style.css';

import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider, useLocation } from 'react-router-dom';

import * as Company from '@/routes/companies';
import * as Contact from '@/routes/contacts';
import ErrorPage from '@/routes/error';
import * as Estimate from '@/routes/estimates';
import * as Invoice from '@/routes/invoices';
import * as Job from '@/routes/jobs';
import Login from '@/routes/login';
import NotFound from '@/routes/notfound';
import * as Project from '@/routes/projects';
import * as Report from '@/routes/reports';
import Root from '@/routes/root';
import * as Session from '@/routes/schedule';
import * as User from '@/routes/users';
import * as WorkOrder from '@/routes/workorders';
import { StoreProvider } from '@/store';

const root = createRoot(document.querySelector('#root'));

function PrivateRoutes() {
  const location = useLocation();
  const from = location.pathname + location.search;
  const accessToken = window.localStorage.getItem('accessToken');
  const refreshToken = window.localStorage.getItem('refreshToken');

  return accessToken && refreshToken ? <Outlet /> : <Navigate to='/login' state={{ from: from }} replace />;
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorPage />}>
      <Route path='/login' element={<Login />} />
      <Route element={<PrivateRoutes />}>
        <Route path='/' element={<Navigate to='/schedule' />} />
        <Route element={<Root />}>
          <Route element={<Session.Menu />} path='schedule' action={Session.singleAction} handle={{ crumb: 'Sessions' }}>
            <Route index errorElement={<ErrorPage />} element={<Session.List />} loader={Session.listLoader} />
            <Route path=':id' action={Session.singleAction} element={<Navigate to='/schedule' />} />
          </Route>

          <Route element={<Contact.Menu />} path='contacts' action={Contact.singleAction} handle={{ crumb: 'Contacts' }}>
            <Route index errorElement={<ErrorPage />} element={<Contact.List />} loader={Contact.listLoader} />
            <Route path=':id' element={<Contact.Single />} errorElement={<ErrorPage />} loader={Contact.singleLoader} action={Contact.singleAction} handle={{ crumb: 'Edit Contact' }} />
            <Route path='company' element={<Navigate to='/contacts' />}>
              <Route path=':id' action={Contact.companyAction} element={<Navigate to='/contacts' />} />
            </Route>
          </Route>

          <Route element={<Company.Menu />} path='companies' action={Company.singleAction} handle={{ crumb: 'Companies' }}>
            <Route index errorElement={<ErrorPage />} element={<Company.List />} loader={Company.listLoader} />
            <Route path=':id' element={<Company.Single />} errorElement={<ErrorPage />} loader={Company.singleLoader} action={Company.singleAction} handle={{ crumb: 'Edit Company' }} />
            <Route path='contact' element={<Navigate to='/companies' />}>
              <Route path=':id' action={Company.contactAction} element={<Navigate to='/companies' />} />
            </Route>
            <Route path='project' element={<Navigate to='/companies' />}>
              <Route path=':id' action={Company.projectAction} element={<Navigate to='/companies' />} />
            </Route>
            <Route path='service' action={Company.serviceAction} element={<Navigate to='/companies' />}>
              <Route path=':id' action={Company.serviceAction} element={<Navigate to='/companies' />} />
            </Route>
            <Route path='stock' action={Company.stockAction} element={<Navigate to='/companies' />}>
              <Route path=':id' action={Company.stockAction} element={<Navigate to='/companies' />} />
            </Route>
            <Route path='expense' action={Company.expenseAction} element={<Navigate to='/companies' />}>
              <Route path=':id' action={Company.expenseAction} element={<Navigate to='/companies' />} />
            </Route>
          </Route>

          <Route element={<Project.Menu />} path='projects' action={Project.singleAction} handle={{ crumb: 'Projects' }}>
            <Route index errorElement={<ErrorPage />} element={<Project.List />} loader={Project.listLoader} />
            <Route path=':id' element={<Project.Single />} errorElement={<ErrorPage />} loader={Project.singleLoader} action={Project.singleAction} handle={{ crumb: 'Edit Project' }} />
            <Route path='service' action={Project.serviceAction} element={<Navigate to='/projects' />}>
              <Route path=':id' action={Project.serviceAction} element={<Navigate to='/projects' />} />
            </Route>
            <Route path='stock' action={Project.stockAction} element={<Navigate to='/projects' />}>
              <Route path=':id' action={Project.stockAction} element={<Navigate to='/projects' />} />
            </Route>
            <Route path='expense' action={Project.expenseAction} element={<Navigate to='/projects' />}>
              <Route path=':id' action={Project.expenseAction} element={<Navigate to='/projects' />} />
            </Route>
          </Route>

          <Route element={<User.Menu />} path='users' action={User.singleAction} handle={{ crumb: 'Users' }}>
            <Route index errorElement={<ErrorPage />} element={<User.List />} loader={User.listLoader} />
            <Route path=':id' element={<User.Single />} errorElement={<ErrorPage />} loader={User.singleLoader} action={User.singleAction} handle={{ crumb: 'Edit User' }} />
            <Route path='service' action={User.serviceAction} element={<Navigate to='/users' />}>
              <Route path=':id' action={User.serviceAction} element={<Navigate to='/users' />} />
            </Route>
            <Route path='pay' element={<Navigate to='/users' />}>
              <Route path=':id' action={User.payAction} element={<Navigate to='/users' />} />
            </Route>
          </Route>

          <Route element={<WorkOrder.Menu />} path='workorders' action={WorkOrder.singleAction} handle={{ crumb: 'Work Orders' }}>
            <Route index errorElement={<ErrorPage />} element={<WorkOrder.List />} loader={WorkOrder.listLoader} />
            <Route path=':id' element={<WorkOrder.Single />} errorElement={<ErrorPage />} loader={WorkOrder.singleLoader} action={WorkOrder.singleAction} handle={{ crumb: 'Edit Work Order' }} />
            <Route path='info' action={WorkOrder.infoAction} element={<Navigate to='/workorders' />}>
              <Route path=':id' action={WorkOrder.infoAction} element={<Navigate to='/workorders' />} />
            </Route>
            <Route path='expense' action={WorkOrder.expenseAction} element={<Navigate to='/workorders' />}>
              <Route path=':id' action={WorkOrder.expenseAction} element={<Navigate to='/workorders' />} />
            </Route>
            <Route path='service' action={WorkOrder.serviceAction} element={<Navigate to='/workorders' />}>
              <Route path=':id' action={WorkOrder.serviceAction} element={<Navigate to='/workorders' />} />
            </Route>
            <Route path='stock' action={WorkOrder.stockAction} element={<Navigate to='/workorders' />}>
              <Route path=':id' action={WorkOrder.stockAction} element={<Navigate to='/workorders' />} />
            </Route>
            <Route path='prices' action={WorkOrder.priceAction} element={<Navigate to='/workorders' />}>
              <Route path=':id' action={WorkOrder.priceAction} element={<Navigate to='/workorders' />} />
            </Route>
          </Route>

          <Route element={<Estimate.Menu />} path='estimates' action={Estimate.singleAction} handle={{ crumb: 'Estimates' }}>
            <Route index errorElement={<ErrorPage />} element={<Estimate.List />} loader={Estimate.listLoader} />
            <Route path=':id' element={<Estimate.Single />} errorElement={<ErrorPage />} loader={Estimate.singleLoader} action={Estimate.singleAction} handle={{ crumb: 'Edit Estimate' }} />
            <Route path='expense' action={Estimate.expenseAction} element={<Navigate to='/estimates' />}>
              <Route path=':id' action={Estimate.expenseAction} element={<Navigate to='/estimates' />} />
            </Route>
            <Route path='service' action={Estimate.serviceAction} element={<Navigate to='/estimates' />}>
              <Route path=':id' action={Estimate.serviceAction} element={<Navigate to='/estimates' />} />
            </Route>
            <Route path='stock' action={Estimate.stockAction} element={<Navigate to='/estimates' />}>
              <Route path=':id' action={Estimate.stockAction} element={<Navigate to='/estimates' />} />
            </Route>
          </Route>

          <Route element={<Job.Menu />} path='jobs' action={Job.singleAction} handle={{ crumb: 'Jobs' }}>
            <Route index errorElement={<ErrorPage />} element={<Job.List />} loader={Job.listLoader} />
            <Route path=':id' element={<Job.Single />} errorElement={<ErrorPage />} loader={Job.singleLoader} action={Job.singleAction} handle={{ crumb: 'Edit Job' }} />
            <Route path='workorder' element={<Navigate to='/jobs' />}>
              <Route path=':id' action={Job.workOrderAction} element={<Navigate to='/jobs' />} />
            </Route>
            <Route path='estimate' element={<Navigate to='/jobs' />}>
              <Route path=':id' action={Job.estimateAction} element={<Navigate to='/jobs' />} />
            </Route>
          </Route>

          <Route element={<Invoice.Menu />} path='invoices' action={Invoice.singleAction} handle={{ crumb: 'Invoices' }}>
            <Route index errorElement={<ErrorPage />} element={<Invoice.List />} loader={Invoice.listLoader} />
            <Route path=':id' element={<Invoice.Single />} errorElement={<ErrorPage />} loader={Invoice.singleLoader} action={Invoice.singleAction} handle={{ crumb: 'View Invoice' }} />
          </Route>

          <Route path='reports' handle={{ crumb: 'Reports' }}>
            <Route element={<Report.Menu />} path='mixers' action={Report.singleAction} handle={{ crumb: 'Mixers' }}>
              <Route index errorElement={<ErrorPage />} element={<Report.List />} loader={Report.listLoader} />
              <Route path=':id' element={<Report.Single />} errorElement={<ErrorPage />} loader={Report.singleLoader} action={Report.singleAction} handle={{ crumb: 'View Report' }} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route element={<NotFound />} path='*' />
    </Route>
  )
);

root.render(
  <React.StrictMode>
    <StoreProvider>
      <RouterProvider router={router} />
    </StoreProvider>
  </React.StrictMode>
);
