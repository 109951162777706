import * as React from 'react';
import { Alert, Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';

import { api } from '@/helper/api';
import { formatErrors } from '@/helper/utility';
import { useStore } from '@/store';

export default function Login() {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [errors, setErrors] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const { setUser } = useStore();

  const year = new Date().getFullYear();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectTo = location.state?.from || '/';

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);

    api
      .post('/auth/login', {
        email: email,
        password: password
      })
      .then((response: any) => {
        window.localStorage.setItem('accessToken', response.data.data.accessToken);
        window.localStorage.setItem('refreshToken', response.data.data.refreshToken);
        setUser(response.data.data);
        navigate(redirectTo, { replace: true });
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        setErrors(formatErrors(error.response.data.errors));
        setIsLoading(false);
        reset();
      });
  }

  function reset() {
    setPassword('');
  }

  return (
    <Container fluid>
      <Row className='mt-5'>
        <Col className='login ms-auto me-auto'>
          <Card>
            <Card.Header>Limetime 2.0</Card.Header>
            <Card.Body>
              <Form noValidate onSubmit={handleSubmit}>
                {message && <Alert variant='danger'>{message}</Alert>}
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label className='visually-hidden'>Email</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='Email'
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    isInvalid={errors?.email ? true : false}
                    disabled={isLoading}
                  />
                  <Form.Control.Feedback type='invalid'>{errors?.email}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mb-3 position-relative' controlId='formBasicPassword'>
                  <Form.Label className='visually-hidden'>Password</Form.Label>
                  <Form.Control
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder='Password'
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    isInvalid={errors?.password ? true : false}
                    disabled={isLoading}
                  />
                  {passwordVisible ? (
                    <Eye className='icon-input' onClick={() => setPasswordVisible(false)} />
                  ) : (
                    <EyeSlash className='icon-input' onClick={() => setPasswordVisible(true)} />
                  )}
                  <Form.Control.Feedback type='invalid'>{errors?.password}</Form.Control.Feedback>
                </Form.Group>
                <Button className='w-100' variant='primary' type='submit' disabled={isLoading}>
                  {isLoading ? (
                    <React.Fragment>
                      <Spinner as='span' size='sm' role='status' aria-hidden='true' />
                      &nbsp;Loading...
                    </React.Fragment>
                  ) : (
                    'Log In'
                  )}
                </Button>
              </Form>
            </Card.Body>
            <Card.Footer className='text-muted'>
              <small>&copy; Nickelright Media {year}</small>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col className='login ms-auto me-auto mt-1 text-end'>
          <a href='/'>Forgot password?</a>
        </Col>
      </Row> */}
    </Container>
  );
}
