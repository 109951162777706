/* eslint-disable sonarjs/cognitive-complexity */
import * as React from 'react';
import { Alert, Card, Table } from 'react-bootstrap';
import { useLoaderData, useLocation } from 'react-router-dom';

import { FormButtons } from '@/components/formbuttons';
import { InfoTable } from '@/components/infotable';
import { ListLink } from '@/components/listlink';
import { deleteSingle, getSingle, postSingle } from '@/helper/apicalls';
import { Report } from '@/helper/interfaces';
import { formatDate, formatMoney, formatTime, removeKeysWithPrefix } from '@/helper/utility';

import { baseURL } from './const';

export async function singleLoader({ params }: any) {
  if (Number.isInteger(Number.parseInt(params.id, 10))) {
    return getSingle(baseURL, params.id);
  } else {
    throw new TypeError('404! This is not a valid URL.');
  }
}

export async function singleAction({ request, params }: any) {
  const body = Object.fromEntries(await request.formData());
  const redirectTo = body.redirectTo;
  delete body.redirectTo;

  if (body.mixerIds) body.mixerIds = JSON.parse(body.mixerIds);

  const bodyCleaned = removeKeysWithPrefix(body, 'element');

  if (request.method == 'POST') {
    return postSingle(baseURL, bodyCleaned);
  } else if (request.method == 'DELETE') {
    return deleteSingle(baseURL, params.id, redirectTo);
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}

export function Single() {
  const data = useLoaderData() as Report;

  const location = useLocation();
  const [redirectTo] = React.useState(location.state?.from || baseURL);

  const isNotFinished = Object.values(data.workOrders.map((workOrder) => workOrder.isFinished)).includes(false);

  return (
    <React.Fragment>
      {data.deleted && (
        <Alert variant='danger' className='mt-3'>
          This report was deleted.
        </Alert>
      )}
      {isNotFinished && (
        <Alert variant='warning' className='mt-3'>
          This report may be incomplete, it contains unfinished work orders.
        </Alert>
      )}
      <Card>
        <Card.Header>Mixer Report &#35;{data.id}</Card.Header>
        <Card.Body>
          <InfoTable
            elements={[
              { title: 'Mixer', content: data.mixer.name, link: '/users/' + data.mixer.id },
              { title: 'Start Date', content: formatDate(data.startDate) },
              { title: 'End Date', content: formatDate(data.endDate) },
              { title: 'Total Pay', content: formatMoney(data.totalPay) }
            ]}
          />
        </Card.Body>
      </Card>
      <Card className='mt-3'>
        <Card.Body>
          <Table striped size='sm'>
            <thead>
              <tr>
                <th></th>
                <th>Date</th>
                <th>Company</th>
                <th>Project</th>
                <th>Service</th>
                <th>Hours/Markup</th>
                <th>Rate/Cost</th>
                <th>Subtotal</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {data.workOrders.length === 0 && (
                <tr>
                  <td colSpan={9}>None</td>
                </tr>
              )}
              {data.workOrders.map((workOrder) => {
                let count = 0;
                const link = <ListLink to={'/workorders/' + workOrder.id}>WO{workOrder.id}</ListLink>;
                return (
                  <React.Fragment key={workOrder.id}>
                    {workOrder.pay.map((p) => {
                      count++;
                      const durationUnit = p.regularDuration <= 1 ? 'hr' : 'hrs';
                      const overTimeDurationUnit = p.overTimeDuration <= 1 ? 'hr' : 'hrs';
                      const doubleTimeDurationUnit = p.doubleTimeDuration <= 1 ? 'hr' : 'hrs';
                      const company = workOrder?.session?.company || workOrder?.company;
                      const project = workOrder?.session?.project || workOrder?.project;

                      return (
                        <tr key={p.id}>
                          {count === 1 ? link : <td></td>}
                          <td>{count === 1 && formatDate(workOrder?.session?.startTime || workOrder?.date)}</td>
                          <td>
                            {count === 1 && (
                              <ListLink to={'/companies/' + company.id} nowrapper>
                                {company.name}
                              </ListLink>
                            )}
                          </td>
                          <td>
                            {count === 1 && (
                              <ListLink to={'/projects/' + project.id} nowrapper>
                                {project.name}
                              </ListLink>
                            )}
                          </td>
                          <td>{p.type.name}</td>
                          <td>
                            {formatTime(p.startTime)}&nbsp;-&nbsp;
                            {formatTime(p.endTime)}
                            {p.overTimeDuration === 0 && (
                              <small>
                                &nbsp;({p.regularDuration} {durationUnit})
                              </small>
                            )}
                            {p.overTimeDuration > 0 && (
                              <React.Fragment>
                                <br />
                                <small className='ms-4'>
                                  Regular Time: {p.regularDuration} {durationUnit}
                                </small>
                                <br />
                                <small className='ms-4'>
                                  Over Time: {p.overTimeDuration} {overTimeDurationUnit}
                                </small>
                              </React.Fragment>
                            )}
                            {p.doubleTimeDuration > 0 && (
                              <React.Fragment>
                                <br />
                                <small className='ms-4'>
                                  Double Time: {p.doubleTimeDuration} {doubleTimeDurationUnit}
                                </small>
                              </React.Fragment>
                            )}
                          </td>
                          <td>{formatMoney(p.regularPayRate)}</td>
                          <td>{formatMoney(p.servicePay)}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan={8}>
                        {!workOrder.isFinished && (
                          <small>
                            <i>* WO{workOrder.id} is not finished, report may be incomplete.</i>
                          </small>
                        )}
                      </td>
                      <td>{formatMoney(workOrder.workOrderPay)}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
          <p className='text-end'>
            <b>Total Pay:</b> {formatMoney(data.totalPay)}
          </p>
        </Card.Body>
      </Card>
      <FormButtons type='Report' redirectTo={redirectTo} showDelete={!data.deleted}>
        <React.Fragment>Are you sure you want to delete this report?</React.Fragment>
      </FormButtons>
    </React.Fragment>
  );
}
