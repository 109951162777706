import * as React from 'react';
import { Table } from 'react-bootstrap';
import { useLoaderData } from 'react-router-dom';

import { ListLink } from '@/components/listlink';
import { Pager } from '@/components/pager';
import { SearchReset } from '@/components/search-reset';
import { Sorter } from '@/components/sorter';
import { getList } from '@/helper/apicalls';
import { ReportListResponse } from '@/helper/interfaces';
import { formatDate, formatMoney } from '@/helper/utility';

import { baseURL, settings } from './const';

export async function listLoader({ request }: any) {
  const parameters = new URL(request.url).searchParams;
  return getList(baseURL, parameters, settings);
}

export function List() {
  const { data, currentPage, numberOfPages, orderBy, order } = useLoaderData() as ReportListResponse;

  return (
    <React.Fragment>
      <Table striped bordered hover size='sm'>
        <thead>
          <tr>
            <Sorter name='id' orderBy={orderBy} order={order}>
              Report &#35;
            </Sorter>
            <Sorter name='name' orderBy={orderBy} order={order} />
            <Sorter name='startDate' orderBy={orderBy} order={order} />
            <Sorter name='endDate' orderBy={orderBy} order={order} />
            <th>Pay</th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data?.map((d) => (
              <tr key={d.id}>
                <ListLink to={baseURL + '/' + d.id}>{d.id}</ListLink>
                <ListLink to={'/users/' + d.mixer.id}>{d.mixer.name}</ListLink>
                <td>{formatDate(d.startDate)}</td>
                <td>{formatDate(d.endDate)}</td>
                <td>{formatMoney(d.totalPay)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>None</td>
            </tr>
          )}
        </tbody>
      </Table>
      <SearchReset />
      <Pager currentPage={currentPage} numberOfPages={numberOfPages} />
    </React.Fragment>
  );
}
