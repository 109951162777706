import { deleteSingle, postSingle, putSingle } from '@/helper/apicalls';

import { baseURL } from './const';

export async function stockAction({ request, params }: any) {
  const body = Object.fromEntries(await request.formData());

  if (body.id) {
    body.projectId = JSON.parse(body.id);
    delete body.id;
  }
  if (body.typeId) body.typeId = JSON.parse(body.typeId);
  if (body.typeName) delete body.typeName;
  if (body.cost) body.cost = JSON.parse(body.cost);

  const URL = baseURL + '/stock';
  if (request.method == 'POST') {
    return postSingle(URL, body);
  } else if (request.method == 'PUT') {
    return putSingle(URL, params.id, body);
  } else if (request.method == 'DELETE') {
    return deleteSingle(URL, params.id);
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}
