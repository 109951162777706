/* eslint-disable sonarjs/cognitive-complexity */
import * as React from 'react';
import { Alert, Card, Table } from 'react-bootstrap';
import { useLoaderData, useLocation } from 'react-router-dom';

import { FormButtons } from '@/components/formbuttons';
import { InfoTable } from '@/components/infotable';
import { ListLink } from '@/components/listlink';
import { deleteSingle, getSingle, postSingle, putSingle } from '@/helper/apicalls';
import { Invoice } from '@/helper/interfaces';
import { formatDate, formatMoney, formatTime } from '@/helper/utility';

import { baseURL } from './const';

export async function singleLoader({ params }: any) {
  if (Number.isInteger(Number.parseInt(params.id, 10))) {
    return getSingle(baseURL, params.id);
  } else {
    throw new TypeError('404! This is not a valid URL.');
  }
}

export async function singleAction({ request, params }: any) {
  const body = Object.fromEntries(await request.formData());
  const redirectTo = body.redirectTo;
  delete body.redirectTo;

  let URL: string;
  if (body.isEstimate === 'true') {
    if (body.estimateIds) body.estimateIds = JSON.parse(body.estimateIds);
    if (body.percentage) body.percentage = JSON.parse(body.percentage);
    if (body.workOrderIds) delete body.workOrderIds;
    if (body.retainerIds) delete body.retainerIds;
    URL = baseURL + '/estimate';
  } else if (body.isEstimate === 'false') {
    if (body.estimateIds) delete body.estimateIds;
    if (body.percentage) delete body.percentage;
    if (body.workOrderIds) body.workOrderIds = JSON.parse(body.workOrderIds);
    if (body.retainerIds) body.retainerIds = JSON.parse(body.retainerIds);
    URL = baseURL + '/workorder';
  }
  delete body.isEstimate;
  if (body.jobId) body.jobId = JSON.parse(body.jobId);

  if (request.method == 'POST') {
    return postSingle(URL, body);
  } else if (request.method == 'PUT') {
    return putSingle(URL, params.id, body);
  } else if (request.method == 'DELETE') {
    return deleteSingle(baseURL, params.id, redirectTo);
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}

export function Single() {
  const data = useLoaderData() as Invoice;

  const location = useLocation();
  const [redirectTo] = React.useState(location.state?.from || baseURL);

  return (
    <React.Fragment>
      {data.deleted && (
        <Alert variant='danger' className='mt-3'>
          This invoice was deleted.
        </Alert>
      )}
      <Card>
        <Card.Header>Invoice &#35;{data.id}</Card.Header>
        <Card.Body>
          <InfoTable
            elements={[
              { title: 'Company', content: data.companyString },
              { title: 'Project', content: data.projectString },
              { title: 'Contact', content: data.contactString },
              { title: 'Job #', content: 'Job ' + data.jobId.toString(), link: '/jobs/' + data.jobId },
              { title: 'P.O. #', content: data.poNumber },
              { title: 'Client Job #', content: data.jobNumber },
              { title: 'Message', content: data.message },
              { title: 'Total Due', content: formatMoney(data.invoiceTotal) }
            ]}
          />
        </Card.Body>
      </Card>

      <Card className='mt-3'>
        <Card.Body>
          <Table size='sm'>
            <thead>
              <tr>
                <th></th>
                <th>Date</th>
                <th>Service</th>
                <th>Hours/Markup</th>
                <th>Rate/Cost</th>
                <th>Subtotal</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {data.workOrders.map((workOrder) => {
                let count = 0;
                const link = <ListLink to={'/workorders/' + workOrder.id}>WO{workOrder.id}</ListLink>;
                const unusedTimeDurationUnit = workOrder.unusedTime <= 1 ? 'hr' : 'hrs';
                return (
                  <React.Fragment key={workOrder.id}>
                    {workOrder.services.map((service) => {
                      count++;
                      const durationUnit = service.regularDuration <= 1 ? 'hr' : 'hrs';
                      const overTimeDurationUnit = service.overTimeDuration <= 1 ? 'hr' : 'hrs';
                      const doubleTimeDurationUnit = service.doubleTimeDuration <= 1 ? 'hr' : 'hrs';
                      return (
                        <tr key={service.id}>
                          {count === 1 ? link : <td></td>}
                          <td>{count === 1 && formatDate(workOrder?.session?.startTime || workOrder?.date)}</td>
                          <td>{service.type.name}</td>
                          <td>
                            {formatTime(service.startTime)}&nbsp;-&nbsp;
                            {formatTime(service.endTime)}
                            {service.overTimeDuration === 0 && (
                              <small>
                                &nbsp;({service.regularDuration} {durationUnit})
                              </small>
                            )}
                            {service.overTimeDuration > 0 && (
                              <React.Fragment>
                                <br />
                                <small className='ms-4'>
                                  Regular Time: {service.regularDuration} {durationUnit}
                                </small>
                                <br />
                                <small className='ms-4'>
                                  Over Time: {service.overTimeDuration} {overTimeDurationUnit}
                                </small>
                              </React.Fragment>
                            )}
                            {service.doubleTimeDuration > 0 && (
                              <React.Fragment>
                                <br />
                                <small className='ms-4'>
                                  Double Time: {service.doubleTimeDuration} {doubleTimeDurationUnit}
                                </small>
                              </React.Fragment>
                            )}
                          </td>
                          <td>{formatMoney(service.regularRate)}</td>
                          <td>{formatMoney(service.subtotal)}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    {workOrder.unusedTimeTotal > 0 && (
                      <React.Fragment>
                        {(() => {
                          count++;
                          return <></>;
                        })()}
                        <tr>
                          {count === 1 ? link : <td></td>}
                          <td>{count === 1 && formatDate(workOrder?.session?.startTime || workOrder?.date)}</td>
                          <td>Unused Time</td>
                          <td>{workOrder.unusedTime + ' ' + unusedTimeDurationUnit}</td>
                          <td>{formatMoney(workOrder.unusedRate)}</td>
                          <td>{formatMoney(workOrder.unusedTimeTotal)}</td>
                          <td></td>
                        </tr>
                      </React.Fragment>
                    )}
                    {workOrder.stock.map((st) => {
                      count++;
                      return (
                        <tr key={st.id}>
                          {count === 1 ? link : <td></td>}
                          <td>{count === 1 && formatDate(workOrder?.session?.startTime || workOrder?.date)}</td>
                          <td>{st.type.name}</td>
                          <td></td>
                          <td></td>
                          <td>{formatMoney(st.cost)}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    {workOrder.expenses.map((expense) => {
                      count++;
                      return (
                        <tr key={expense.id}>
                          {count === 1 ? link : <td></td>}
                          <td>{count === 1 && formatDate(workOrder?.session?.startTime || workOrder?.date)}</td>
                          <td>{expense.type.name}</td>
                          <td>{expense.markup}%</td>
                          <td>{formatMoney(expense.cost)}</td>
                          <td>{formatMoney(expense.subtotal)}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    {workOrder.priceAdjustment !== 0 && (
                      <React.Fragment>
                        {(() => {
                          count++;
                          return <></>;
                        })()}
                        <tr>
                          {count === 1 ? link : <td></td>}
                          <td>{count === 1 && formatDate(workOrder?.session?.startTime || workOrder?.date)}</td>
                          <td>Price Adjustment</td>
                          <td></td>
                          <td></td>
                          <td>{formatMoney(workOrder.priceAdjustment)}</td>
                          <td></td>
                        </tr>
                      </React.Fragment>
                    )}
                    {(() => {
                      count++;
                      return <></>;
                    })()}
                    <tr>
                      {count === 1 ? link : <td></td>}
                      <td>{count === 1 && formatDate(workOrder?.session?.startTime || workOrder?.date)}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{formatMoney(workOrder.workOrderTotal)}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
              {data.estimates.map((estimate) => {
                let count = 0;
                const link = <ListLink to={'/estimates/' + estimate.id}>EST{estimate.id}</ListLink>;
                return (
                  <React.Fragment key={estimate.id}>
                    {estimate.services.map((service) => {
                      count++;
                      return (
                        <tr key={service.id}>
                          {count === 1 ? link : <td></td>}
                          <td>{count === 1 && formatDate(estimate.date)}</td>
                          <td>{service.type.name}</td>
                          <td>{service.regularDuration}</td>
                          <td>{formatMoney(service.regularRate)}</td>
                          <td>{formatMoney(service.subtotal)}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    {estimate.stock.map((st) => {
                      count++;
                      return (
                        <tr key={st.id}>
                          {count === 1 ? link : <td></td>}
                          <td>{count === 1 && formatDate(estimate.date)}</td>
                          <td>{st.type.name}</td>
                          <td></td>
                          <td></td>
                          <td>{formatMoney(st.cost)}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    {estimate.expenses.map((expense) => {
                      count++;
                      return (
                        <tr key={expense.id}>
                          {count === 1 ? link : <td></td>}
                          <td>{count === 1 && formatDate(estimate.date)}</td>
                          <td>{expense.type.name}</td>
                          <td></td>
                          <td></td>
                          <td>{formatMoney(expense.subtotal)}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    {(() => {
                      count++;
                      return <></>;
                    })()}
                    <tr>
                      {count === 1 ? link : <td></td>}
                      <td>{count === 1 && formatDate(estimate.date)}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{formatMoney(estimate.estimateTotal)}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
          <p className='text-end'>
            {data.retainerTotal > 0 && (
              <React.Fragment>
                <b>Subtotal:</b> {formatMoney(data.preRetainerTotal)}
                <br />
                <b>Retainer:</b> -{formatMoney(data.retainerTotal)}
                <br />
              </React.Fragment>
            )}
            {data.percentage && (
              <React.Fragment>
                <b>Estimate Total:</b> {formatMoney(data.estimateTotal)}
                <br />
                <b>Deposit:</b> {data.percentage}&#37;
                <br />
              </React.Fragment>
            )}
            <b>Total Due:</b> {formatMoney(data.invoiceTotal)}
          </p>
        </Card.Body>
      </Card>
      <FormButtons type='Invoice' redirectTo={redirectTo} showDelete={!data.deleted}>
        <React.Fragment>Are you sure you want to delete this invoice?</React.Fragment>
      </FormButtons>
    </React.Fragment>
  );
}
