/* eslint-disable sonarjs/no-duplicate-string */
import { addDays, addMinutes, format, isSameDay, setHours, setMinutes, startOfDay } from 'date-fns';
import * as React from 'react';
import { Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import { FormContext } from '@/helper/context';

import { CustomInput } from './datepicker-custom';

interface Properties {
  value?: Value;
  errors?: Errors | undefined;
}
interface Value {
  startTime: string;
  endTime: string;
}
interface Errors {
  startTime: string;
  endTime: string;
}

export function SelectorTime({ value, errors }: Properties) {
  const { changeTracker, setWasChanged, disabled } = React.useContext(FormContext);

  const [startTimeState, setStartTimeState] = React.useState(
    value?.startTime ? new Date(value.startTime) : new Date(new Date().setHours(10, 0, 0, 0))
  );
  const [endTimeState, setEndTimeState] = React.useState(
    value?.endTime ? new Date(value.endTime) : new Date(new Date().setHours(18, 0, 0, 0))
  );
  const [maxDate, setMaxDate] = React.useState(addDays(startTimeState, 1));

  const initMinTime = isSameDay(startTimeState, endTimeState)
    ? addMinutes(startTimeState, 15)
    : startOfDay(endTimeState);
  const [minTime, setMinTime] = React.useState(initMinTime);

  const [maxTime] = React.useState(setHours(setMinutes(new Date(), 45), 23));

  function setStart(date: Date) {
    const endTimeOnly = format(endTimeState, 'HH:mm');
    const minEndTime = addMinutes(date, 15);
    if (isSameDay(startTimeState, endTimeState)) {
      if (format(date, 'HH:mm') > endTimeOnly) {
        setEndTimeState(minEndTime);
      } else {
        const [hours, minutes] = endTimeOnly.split(':');
        const endTimeDateObject = setHours(
          setMinutes(new Date(date), Number.parseInt(minutes, 10)),
          Number.parseInt(hours, 10)
        );
        setEndTimeState(endTimeDateObject);
      }
      setMinTime(minEndTime);
    } else {
      const [hours, minutes] = endTimeOnly.split(':');
      const endTimeDateObject = setHours(
        setMinutes(new Date(date), Number.parseInt(minutes, 10)),
        Number.parseInt(hours, 10)
      );
      setEndTimeState(addDays(endTimeDateObject, 1));
      setMinTime(startOfDay(endTimeDateObject));
    }
    setStartTimeState(date);
    setMaxDate(addDays(date, 1));

    const didChange = date?.toISOString() == value.startTime ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      startTime: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }

  function setEnd(date: Date) {
    if (isSameDay(startTimeState, date)) {
      setMinTime(addMinutes(startTimeState, 15));
    } else {
      setMinTime(startOfDay(date));
    }
    setEndTimeState(date);

    const didChange = date?.toISOString() == value.endTime ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      endTime: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }

  const [nextDay, setNextDay] = React.useState(isSameDay(startTimeState, endTimeState) ? false : true);
  function handleNextDay() {
    if (nextDay) {
      setNextDay(false);
      if (format(startTimeState, 'HH:mm') > format(endTimeState, 'HH:mm')) {
        setEnd(addMinutes(startTimeState, 15));
      } else {
        setEnd(addDays(endTimeState, -1));
      }
    } else {
      setNextDay(true);
      setEnd(addDays(endTimeState, 1));
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Form.Control type='hidden' name='startTime' value={startTimeState?.toISOString()} />
        <Form.Group className='mt-2 col-4'>
          <span className={'d-block form-label' + (errors?.startTime ? ' is-invalid' : '')}>Start Time</span>
          <DatePicker
            id='startTime'
            selected={startTimeState}
            disabled={disabled}
            onChange={(date) => setStart(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat='h:mm aa'
            customInput={<CustomInput id='startTimeSelector' disabled={disabled} />}
          />
          <Form.Control.Feedback type='invalid'>{errors?.startTime}</Form.Control.Feedback>
        </Form.Group>
        <Form.Control type='hidden' name='endTime' value={endTimeState?.toISOString()} />
        <Form.Group className='mt-2 col-4'>
          <span className={'d-block form-label' + (errors?.endTime ? ' is-invalid' : '')}>End Time</span>
          <DatePicker
            id='endTime'
            selected={endTimeState}
            disabled={disabled}
            onChange={(date) => setEnd(date)}
            minDate={startTimeState}
            maxDate={maxDate}
            minTime={minTime}
            maxTime={maxTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat='h:mm aa'
            customInput={<CustomInput id='endTimeSelector' disabled={disabled} />}
          />
          <Form.Control.Feedback type='invalid'>{errors?.endTime}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='nextDay' className='mt-5 col-4'>
          <Form.Check id='nextDay' type='checkbox' label='Past Midnight' checked={nextDay} onChange={handleNextDay} />
        </Form.Group>
      </Row>
    </React.Fragment>
  );
}
