import * as React from 'react';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useFetcher } from 'react-router-dom';

interface Properties {
  title: string;
  url: string;
  button?: string;
  deleteId?: number;
  callback?: (number: number) => void;
  children: React.ReactNode;
}

export const ModalDelete = React.forwardRef(function ModalDelete(
  { title, url, button = 'Delete', deleteId, callback, children }: Properties,
  reference
) {
  const fetcher = useFetcher();

  const [modal, setModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [deleteIdBody, setDeleteIdBody] = React.useState<number>();

  React.useEffect(() => {
    if (fetcher.state == 'loading' || fetcher.state == 'submitting') {
      setIsLoading(true);
    } else {
      if (fetcher.data?.status == 200) {
        setModal(false);
        if (typeof callback === 'function') {
          callback(deleteIdBody);
        }
      } else {
        setError(fetcher.data);
        setIsLoading(false);
      }
    }
  }, [fetcher]);

  React.useImperativeHandle(reference, () => ({
    openModal(id: number) {
      setIsLoading(false);
      setDeleteIdBody(id);
      setModal(true);
    }
  }));

  function closeModal() {
    if (!isLoading) {
      setModal(false);
      setError('');
    }
  }
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    fetcher.submit(deleteIdBody ? { ids: JSON.stringify([deleteIdBody]) } : {}, {
      method: 'delete',
      action: url + (deleteId ? '/' + deleteId : '')
    });
  }
  return (
    <Modal show={modal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant='danger'>{error}</Alert>}
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Form onSubmit={handleSubmit} noValidate>
          <Button variant='secondary' type='button' className='me-2' onClick={closeModal} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant='danger' type='submit' disabled={isLoading}>
            {!isLoading && <React.Fragment>{button}</React.Fragment>}
            {isLoading && (
              <React.Fragment>
                <Spinner as='span' size='sm' role='status' aria-hidden='true' />
                &nbsp;Loading...
              </React.Fragment>
            )}
          </Button>
        </Form>
      </Modal.Footer>
    </Modal>
  );
});
