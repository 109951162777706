/* eslint-disable security/detect-object-injection */
import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { getAll } from '@/helper/apicalls';
import { FormContext } from '@/helper/context';
import { camelToTitle } from '@/helper/utility';
import { useStore } from '@/store';

interface Properties {
  name: string;
  value?: number;
  valueSelect?: number;
  size?: string;
  fetchURL: string;
  addToken?: () => void;
}

interface Item {
  id: number;
  name: string;
}

export function SelectorAPI({ name, value, valueSelect, size, fetchURL, addToken }: Properties) {
  const displayName = camelToTitle(name);
  const nameId = name + 'Id';

  const { user } = useStore();

  const [selected, setSelected] = React.useState<Item[]>([{ id: 0, name: '' }]);
  const [selectList, setSelectList] = React.useState<Item[]>();
  const [isLoading, setIsLoading] = React.useState(true);
  const setSize = size || 'col-12';

  const { changeTracker, setWasChanged, disabled, errors } = React.useContext(FormContext);

  const showAddToken = user?.role?.editCompanies && user?.role?.editContacts && typeof addToken === 'function';

  React.useEffect(() => {
    async function getdata() {
      const id = valueSelect ?? value;
      const temporaryList = (await getAll(fetchURL)) as Item[];
      setSelectList(temporaryList);
      const temporaryFind = temporaryList.find((item) => item.id === id);
      if (temporaryFind) setSelected([temporaryFind]);
    }
    getdata().then(() => {
      setIsLoading(false);
    });
    const didChange = valueSelect ? (valueSelect == value ? false : true) : false;
    changeTracker.current = {
      ...changeTracker.current,
      [name]: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }, []);

  function onChange(event: any) {
    setSelected(event);

    const didChange = event[0]?.id == value ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      [name]: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }

  return (
    <React.Fragment>
      <Form.Control type='hidden' name={nameId} value={JSON.stringify(selected[0]?.id) || '0'} />
      <Form.Group className={`mt-3 ${setSize}`}>
        <Form.Label htmlFor={name}>{displayName}</Form.Label>
        {showAddToken && (
          <Button variant='primary' type='button' size='sm' className='float-end btn-xs' onClick={() => addToken()}>
            + Add
          </Button>
        )}
        <Typeahead
          id={name}
          inputProps={{ id: name }}
          options={selectList?.length > 0 ? selectList : []}
          labelKey='name'
          onChange={onChange}
          selected={selected}
          isInvalid={errors[nameId] ? true : false}
          clearButton
          highlightOnlyResult={true}
          disabled={disabled || isLoading}
          placeholder={isLoading ? 'Loading...' : `--Select a ${displayName}--`}
          isLoading={isLoading}
          emptyLabel='None Found...'
        />
        <Form.Control.Feedback type='invalid'>{errors[nameId]}</Form.Control.Feedback>
      </Form.Group>
    </React.Fragment>
  );
}
