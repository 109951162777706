import * as React from 'react';
import { Table } from 'react-bootstrap';
import { Check2 } from 'react-bootstrap-icons';
import { useLoaderData } from 'react-router-dom';

import { ListLink } from '@/components/listlink';
import { Pager } from '@/components/pager';
import { SearchReset } from '@/components/search-reset';
import { Sorter } from '@/components/sorter';
import { getList } from '@/helper/apicalls';
import { UserListResponse } from '@/helper/interfaces';
import { formatPhone } from '@/helper/utility';

import { baseURL, settings } from './const';

export async function listLoader({ request }: any) {
  const parameters = new URL(request.url).searchParams;
  return getList(baseURL, parameters, settings);
}

export function List() {
  const { data, currentPage, numberOfPages, orderBy, order } = useLoaderData() as UserListResponse;

  return (
    <React.Fragment>
      <Table striped bordered hover size='sm'>
        <thead>
          <tr>
            <Sorter name='firstName' orderBy={orderBy} order={order} />
            <Sorter name='lastName' orderBy={orderBy} order={order} />
            <Sorter name='email' orderBy={orderBy} order={order} />
            <th>Phone</th>
            <th>Active?</th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data?.map((d) => (
              <tr key={d.id}>
                <ListLink to={`${baseURL}/${d.id}`}>{d.firstName}</ListLink>
                <ListLink to={`${baseURL}/${d.id}`}>{d.lastName}</ListLink>
                <td>{d.email}</td>
                <td>{formatPhone(d.phone)}</td>
                <td className='text-center'>{d.isActive ? <Check2 aria-label='yes' /> : ''}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>None</td>
            </tr>
          )}
        </tbody>
      </Table>
      <SearchReset />
      <Pager currentPage={currentPage} numberOfPages={numberOfPages} />
    </React.Fragment>
  );
}
