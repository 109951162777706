import * as React from 'react';
import { Button, Card, Form, Table } from 'react-bootstrap';
import { PencilSquare, Trash3 } from 'react-bootstrap-icons';

import { Input } from '@/components/input';
import { InputNumber } from '@/components/input-number';
import { ModalDelete } from '@/components/modal-delete';
import { ModalForm } from '@/components/modal-form';
import { SelectorAPISimple } from '@/components/selector-api-simple';
import { Switch } from '@/components/switch';
import { Expense } from '@/helper/interfaces';
import { formatMoney } from '@/helper/utility';
import { useStore } from '@/store';

interface Properties {
  baseURL: string;
  id: number;
  expenses: Expense[];
  total: number;
  disabled: boolean;
}

export function Expenses({ baseURL, id, expenses, total, disabled }: Properties) {
  const { user } = useStore();

  const [editExpense, setEditExpense] = React.useState<Expense>();
  const [isNew, setIsNew] = React.useState<boolean>();
  const [markup, setMarkup] = React.useState<string>();
  const [customMarkup, setCustomMarkup] = React.useState<boolean>();

  function openModal(expense?: Expense) {
    if (expense) {
      setEditExpense(expense);
      setCustomMarkup(expense.customMarkup);
      setMarkup(expense.customMarkup ? expense.markup.toString() : '');
      setIsNew(false);
    } else {
      // eslint-disable-next-line unicorn/no-useless-undefined
      setEditExpense(undefined);
      setCustomMarkup(false);
      setIsNew(true);
    }
    modal.current.openModal();
  }

  function openModalDelete(expense: Expense) {
    setEditExpense(expense);
    modalDelete.current.openModal();
  }

  const modal = React.useRef(null);
  const modalDelete = React.useRef(null);

  return (
    <React.Fragment>
      <Card className='mt-3'>
        <Card.Body>
          <Card.Title className='clearfix'>
            Expenses
            <Button type='button' size='sm' className='float-end' onClick={() => openModal()} disabled={disabled}>
              + Add
            </Button>
          </Card.Title>
          <Table striped size='sm'>
            <thead>
              <tr>
                <th>Type </th>
                <th>Description</th>
                <th>Cost</th>
                {user?.role?.managePrices && (
                  <React.Fragment>
                    <th>Markup</th>
                    <th>Subtotal</th>
                  </React.Fragment>
                )}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {expenses.length === 0 && (
                <tr>
                  <td colSpan={6}>None</td>
                </tr>
              )}
              {expenses.map((expense) => {
                return (
                  <tr key={expense.id}>
                    <td>{expense.type.name}</td>
                    <td>{expense.description}</td>
                    <td>{formatMoney(expense.cost)}</td>
                    {user?.role?.managePrices && (
                      <React.Fragment>
                        <td>{expense.markup}&#37;</td>
                        <td>{formatMoney(expense.subtotal)}</td>
                      </React.Fragment>
                    )}
                    <td className='text-end'>
                      <React.Fragment>
                        <Button
                          variant='link'
                          className='btn-icon me-1'
                          onClick={() => openModal(expense)}
                          disabled={disabled}
                        >
                          <PencilSquare aria-label='edit' />
                        </Button>
                        <Button
                          variant='link'
                          className='btn-icon me-1'
                          onClick={() => openModalDelete(expense)}
                          disabled={disabled}
                        >
                          <Trash3 area-label='delete' />
                        </Button>
                      </React.Fragment>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {user?.role?.managePrices && (
            <p className='text-end'>
              <b>Expenses Total:</b> {formatMoney(total)}
            </p>
          )}
        </Card.Body>
      </Card>

      <ModalForm
        title={(isNew ? 'Add' : 'Edit') + ' Expense'}
        url={baseURL + '/expense'}
        putId={editExpense?.id}
        ref={modal}
        draggable
      >
        <Form.Control type='hidden' name='id' value={id} />
        <SelectorAPISimple name='type' value={editExpense?.type?.id} fetchURL='/workorders/expense/types' />
        <InputNumber name='cost' value={editExpense?.cost.toString()} money />
        {user?.role?.managePrices && (
          <React.Fragment>
            <Switch name='customMarkup' value={customMarkup} setValue={setCustomMarkup} />
            {customMarkup && <InputNumber name='markup' value={markup} percentage />}
          </React.Fragment>
        )}
        <Input name='description' size='col-12' value={editExpense?.description} />
      </ModalForm>

      <ModalDelete title='Delete Expense' url={baseURL + '/expense'} deleteId={editExpense?.id} ref={modalDelete}>
        Are you sure you want to delete this expense?
      </ModalDelete>
    </React.Fragment>
  );
}
