import { putSingle } from '@/helper/apicalls';

import { baseURL } from './const';

export async function estimateAction({ request, params }: any) {
  const body = Object.fromEntries(await request.formData());

  if (body.estimateIds) body.estimateIds = JSON.parse(body.estimateIds);
  if (body.ids) {
    body.estimateIds = JSON.parse(body.ids);
    delete body.ids;
  }

  const URL = baseURL + '/estimate';
  if (request.method == 'PUT') {
    return putSingle(URL + '/add', params.id, body);
  } else if (request.method == 'DELETE') {
    return putSingle(URL + '/remove', params.id, body);
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}
