import * as React from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';
import { useLoaderData, useLocation } from 'react-router-dom';

import { CustomPrices } from '@/components/customprices';
import { FormButtons } from '@/components/formbuttons';
import { ProjectForm } from '@/components/forms';
import { InfoTable } from '@/components/infotable';
import { ListLink } from '@/components/listlink';
import { ModalForm } from '@/components/modal-form';
import { deleteSingle, getSingle, postSingle, putSingle } from '@/helper/apicalls';
import { Project } from '@/helper/interfaces';
import { useStore } from '@/store';

import { baseURL } from './const';

export async function singleLoader({ params }: any) {
  if (Number.isInteger(Number.parseInt(params.id, 10))) {
    return getSingle(baseURL, params.id);
  } else {
    throw new TypeError('404! This is not a valid URL.');
  }
}

export async function singleAction({ request, params }: any) {
  const body = Object.fromEntries(await request.formData());
  const redirectTo = body.redirectTo;
  delete body.redirectTo;

  if (body.companyId) body.companyId = JSON.parse(body.companyId);

  if (request.method == 'POST') {
    return postSingle(baseURL, body);
  } else if (request.method == 'PUT') {
    return putSingle(baseURL, params.id, body);
  } else if (request.method == 'DELETE') {
    return deleteSingle(baseURL, params.id, redirectTo);
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}

export function Single() {
  const { user } = useStore();
  const data = useLoaderData() as Project;

  const location = useLocation();
  const [redirectTo] = React.useState(location.state?.from || baseURL);

  const disabled = data.deleted || data.company.deleted || !user?.role?.editProjects;

  const modal = React.useRef(null);

  return (
    <React.Fragment>
      {data.deleted && (
        <Alert variant='danger' className='mt-3'>
          This project was deleted and can no longer be edited.
        </Alert>
      )}
      {data.company.deleted && !data.deleted && (
        <Alert variant='danger' className='mt-3'>
          The parent company&nbsp;
          <ListLink to={'/companies/' + data.company.id} nowrapper>
            {data.company.name}
          </ListLink>
          &nbsp;was deleted so this project can no longer be edited.
        </Alert>
      )}
      {!user?.role?.editProjects && !data.deleted && !data.company.deleted && (
        <Alert variant='warning' className='mt-3'>
          You are not authorized to edit this page.
        </Alert>
      )}
      <Card>
        <Card.Body>
          <Card.Title className='clearfix'>
            Project Info
            <Button
              type='button'
              size='sm'
              className='float-end'
              onClick={() => modal.current.openModal()}
              disabled={disabled}
            >
              <PencilSquare className='icon-align' /> Edit
            </Button>
          </Card.Title>
          <InfoTable
            elements={[
              { title: 'Name', content: data.name },
              { title: 'Company', content: data.company.name, link: '/companies/' + data.company.id },
              { title: 'Notes', content: data.notes },
              ...(user?.role?.privateNotes ? [{ title: 'Producer Notes', content: data.privateNotes }] : [])
            ]}
          />
        </Card.Body>
      </Card>

      {user?.role?.managePrices && (
        <CustomPrices
          id={data.id}
          value={{ expenses: data.expenses, services: data.services, stock: data.stock }}
          baseURL={baseURL}
          disabled={disabled}
        />
      )}

      <FormButtons type='Project' redirectTo={redirectTo} showDelete={!disabled}>
        <React.Fragment>Are you sure you want to delete {data.name}?</React.Fragment>
      </FormButtons>

      <ModalForm title='Edit Info' url={baseURL} putId={data.id} ref={modal} draggable>
        <ProjectForm data={data} />
      </ModalForm>
    </React.Fragment>
  );
}
