import * as React from 'react';
import { Alert, Container, Nav, Row } from 'react-bootstrap';
import { Outlet, useMatches } from 'react-router-dom';

import { Input } from '@/components/input';
import { InputPhone } from '@/components/input-phone';
import { ModalForm } from '@/components/modal-form';
import { PageHeader } from '@/components/pageheader';
import { SearchBar } from '@/components/search-bar';
import { SelectorList } from '@/components/selector-list';
import { Match } from '@/helper/interfaces';
import { allCountries, allStates } from '@/helper/lists';
import { useStore } from '@/store';

import { baseURL } from './const';

export function Menu() {
  const { user } = useStore();

  const matches = useMatches() as Match[];
  const isList = matches.at(-1).data?.isList;

  const crumbs = matches
    .filter((match: any) => {
      return Boolean(match.handle?.crumb);
    })
    .map((match: any) => {
      return { name: match.handle?.crumb, link: match.pathname };
    });

  const modal = React.useRef(null);

  return (
    <React.Fragment>
      <Container fluid={isList ? true : false}>
        {user?.role?.users ? (
          <React.Fragment>
            {isList ? (
              <React.Fragment>
                <PageHeader>Users</PageHeader>
                <Nav>
                  <Nav.Link onClick={() => modal.current.openModal()}>Add User</Nav.Link>
                  <SearchBar />
                </Nav>
              </React.Fragment>
            ) : (
              <PageHeader crumbs={crumbs}>Edit User</PageHeader>
            )}
            <Outlet />
          </React.Fragment>
        ) : (
          <Row className='justify-content-center'>
            <Alert variant='warning' className='col-6 mt-5'>
              You are not authorized to access this page
            </Alert>
          </Row>
        )}
      </Container>

      <ModalForm title='Add User' size='lg' url={baseURL} redirect={baseURL} ref={modal} draggable>
        <Input name='firstName' size='col-6' />
        <Input name='lastName' size='col-6' />
        <Input name='email' size='col-6' />
        <InputPhone size='col-6' />
        <Input name='address' />
        <Input name='city' size='col-6' />
        <SelectorList name='state' list={allStates} size='col-4' />
        <Input name='zip' size='col-2' />
        <SelectorList name='country' list={allCountries} size='col-12' />
      </ModalForm>
    </React.Fragment>
  );
}
