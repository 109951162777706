import * as React from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';
import { useLoaderData, useLocation } from 'react-router-dom';

import { CustomPay } from '@/components/custompay';
import { FormButtons } from '@/components/formbuttons';
import { InfoTable } from '@/components/infotable';
import { Input } from '@/components/input';
import { InputPhone } from '@/components/input-phone';
import { ModalForm } from '@/components/modal-form';
import { Patcher } from '@/components/patcher';
import { PatcherAPISelector } from '@/components/patcher-api-selector';
import { PatcherSwitch } from '@/components/patcher-switch';
import { SelectorList } from '@/components/selector-list';
import { deleteSingle, getSingle, patchSingle, postSingle, putSingle } from '@/helper/apicalls';
import { User } from '@/helper/interfaces';
import { allCountries, allStates } from '@/helper/lists';
import { formatAddress, formatPhone } from '@/helper/utility';

import { baseURL } from './const';

export async function singleLoader({ params }: any) {
  if (Number.isInteger(Number.parseInt(params.id, 10))) {
    return getSingle(baseURL, params.id);
  } else {
    throw new TypeError('404! This is not a valid URL.');
  }
}

export async function singleAction({ request, params }: any) {
  const body = Object.fromEntries(await request.formData());
  const redirectTo = body.redirectTo;
  delete body.redirectTo;

  if (body.roleId) body.roleId = JSON.parse(body.roleId);

  if (request.method == 'POST') {
    return postSingle(baseURL, body);
  } else if (request.method == 'PUT') {
    return putSingle(baseURL, params.id, body);
  } else if (request.method == 'PATCH') {
    return patchSingle(baseURL, params.id, body);
  } else if (request.method == 'DELETE') {
    return deleteSingle(baseURL, params.id, redirectTo);
  } else {
    return;
  }
}

export function Single() {
  const data = useLoaderData() as User;

  const location = useLocation();
  const [redirectTo] = React.useState(location.state?.from || baseURL);

  const isSuperAdmin = data.id === 1 ? true : false;

  const modal = React.useRef(null);

  return (
    <React.Fragment>
      {data.deleted && (
        <Alert variant='danger' className='mt-3'>
          This user was deleted and can no longer be edited.
        </Alert>
      )}
      {isSuperAdmin && (
        <Alert variant='warning' className='mt-3'>
          Super Admin cannot be edited.
        </Alert>
      )}

      <Card>
        <Card.Body>
          <Card.Title className='clearfix'>
            User Info
            <Button
              type='button'
              size='sm'
              className='float-end'
              onClick={() => modal.current.openModal()}
              disabled={data.deleted || isSuperAdmin}
            >
              <PencilSquare className='icon-align' /> Edit
            </Button>
          </Card.Title>
          <InfoTable
            elements={[
              { title: 'Name', content: data.name },
              { title: 'Email', content: data.email },
              ...(isSuperAdmin
                ? []
                : [
                    { title: 'Phone', content: formatPhone(data.phone) },
                    {
                      title: 'Address',
                      content: formatAddress(data.address, data.city, data.state, data.zip, data.country)
                    }
                  ])
            ]}
          />
        </Card.Body>
      </Card>

      <Card className='mt-3'>
        <Card.Body>
          <Card.Title className='clearfix'>Permissions</Card.Title>
          <Patcher
            id={data.id}
            values={{
              isActive: data.isActive.toString(),
              isMixer: data.isMixer.toString(),
              isAssistant: data.isAssistant.toString(),
              roleId: data.roleId.toString()
            }}
            url='/users'
            disabled={data.deleted}
          >
            <PatcherSwitch name='isActive' displayName='Active' inactive={isSuperAdmin} />
            <PatcherSwitch name='isMixer' displayName='Mixer' inactive={isSuperAdmin} />
            <PatcherSwitch name='isAssistant' displayName='Assistant' inactive={isSuperAdmin} />
            <PatcherAPISelector name='role' fetchURL='/users/roles' size='col-3' inactive={isSuperAdmin} />
          </Patcher>
        </Card.Body>
      </Card>

      {data.isMixer && (
        <CustomPay
          id={data.id}
          value={{
            services: data.customRates,
            rate: data.rate,
            usePercentage: data.usePercentage,
            percentage: data.percentage
          }}
          baseURL={baseURL}
          disabled={data.deleted}
        />
      )}

      <FormButtons type='User' redirectTo={redirectTo} showDelete={!data.deleted && !isSuperAdmin}>
        <React.Fragment>Are you sure you want to delete {data.name}?</React.Fragment>
      </FormButtons>

      <ModalForm title='Edit Info' size='lg' url={baseURL} putId={data.id} ref={modal} draggable>
        <Input name='firstName' size='col-6' value={data.firstName} />
        <Input name='lastName' size='col-6' value={data.lastName} />
        <Input name='email' size='col-6' value={data.email} />
        <InputPhone size='col-6' value={data.phone} />
        <Input name='address' value={data.address} />
        <Input name='city' size='col-6' value={data.city} />
        <SelectorList name='state' list={allStates} size='col-4' value={data.state} />
        <Input name='zip' size='col-2' value={data.zip} />
        <SelectorList name='country' list={allCountries} size='col-12' value={data.country} />
      </ModalForm>
    </React.Fragment>
  );
}
