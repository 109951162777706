import * as React from 'react';
import { Button, Card, Form, Table } from 'react-bootstrap';
import { PencilSquare, Trash3 } from 'react-bootstrap-icons';

import { Input } from '@/components/input';
import { InputNumber } from '@/components/input-number';
import { ModalDelete } from '@/components/modal-delete';
import { ModalForm } from '@/components/modal-form';
import { SelectorAPISimple } from '@/components/selector-api-simple';
import { Switch } from '@/components/switch';
import { Stock } from '@/helper/interfaces';
import { formatMoney } from '@/helper/utility';
import { useStore } from '@/store';

interface Properties {
  baseURL: string;
  id: number;
  stock: Stock[];
  total: number;
  disabled: boolean;
}

export function Stock({ baseURL, id, stock, total, disabled }: Properties) {
  const { user } = useStore();

  const [editStock, setEditStock] = React.useState<Stock>();
  const [isNew, setIsNew] = React.useState<boolean>();
  const [cost, setCost] = React.useState<string>();
  const [customCost, setCustomCost] = React.useState<boolean>();
  const [quantity, setQuantity] = React.useState<number>();

  function openModal(stock?: Stock) {
    if (stock) {
      setEditStock(stock);
      setCustomCost(stock.customCost);
      setCost(stock.customCost ? stock.cost.toString() : '');
      setQuantity(stock.quantity);
      setIsNew(false);
    } else {
      // eslint-disable-next-line unicorn/no-useless-undefined
      setEditStock(undefined);
      setCustomCost(false);
      setQuantity(1);
      setIsNew(true);
    }
    modal.current.openModal();
  }

  function openModalDelete(stock: Stock) {
    setEditStock(stock);
    modalDelete.current.openModal();
  }

  const modal = React.useRef(null);
  const modalDelete = React.useRef(null);

  return (
    <React.Fragment>
      <Card className='mt-3'>
        <Card.Body>
          <Card.Title className='clearfix'>
            Stock
            <Button type='button' size='sm' className='float-end' onClick={() => openModal()} disabled={disabled}>
              + Add
            </Button>
          </Card.Title>
          <Table striped size='sm'>
            <thead>
              <tr>
                <th>Type</th>
                <th>Description</th>
                {user?.role?.managePrices && <th>Cost</th>}
                <th>Quantity</th>
                {user?.role?.managePrices && <th>Subtotal</th>}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {stock.length === 0 && (
                <tr>
                  <td colSpan={6}>None</td>
                </tr>
              )}
              {stock.map((st) => {
                return (
                  <tr key={st.id}>
                    <td>{st.type.name}</td>
                    <td>{st.description}</td>
                    {user?.role?.managePrices && <td>{formatMoney(st.cost)}</td>}
                    <td>{st.quantity}</td>
                    {user?.role?.managePrices && <td>{formatMoney(st.subtotal)}</td>}
                    <td className='text-end'>
                      <React.Fragment>
                        <Button
                          variant='link'
                          className='btn-icon me-1'
                          onClick={() => openModal(st)}
                          disabled={disabled}
                        >
                          <PencilSquare aria-label='edit' />
                        </Button>
                        <Button
                          variant='link'
                          className='btn-icon me-1'
                          onClick={() => openModalDelete(st)}
                          disabled={disabled}
                        >
                          <Trash3 area-label='delete' />
                        </Button>
                      </React.Fragment>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {user?.role?.managePrices && (
            <p className='text-end'>
              <b>Stock Total:</b> {formatMoney(total)}
            </p>
          )}
        </Card.Body>
      </Card>

      <ModalForm
        title={(isNew ? 'Add' : 'Edit') + ' Stock'}
        url={baseURL + '/stock'}
        putId={editStock?.id}
        ref={modal}
        draggable
      >
        <Form.Control type='hidden' name='id' value={id} />
        <SelectorAPISimple name='type' value={editStock?.type?.id} fetchURL='/workorders/stock/types' />
        <InputNumber name='quantity' value={quantity?.toString()} />
        {user?.role?.managePrices && (
          <React.Fragment>
            <Switch name='customCost' value={customCost} setValue={setCustomCost} />
            {customCost && <InputNumber name='cost' value={cost} />}
          </React.Fragment>
        )}
        <Input name='description' size='col-12' value={editStock?.description} />
      </ModalForm>

      <ModalDelete title='Delete Stock' url={baseURL + '/stock'} deleteId={editStock?.id} ref={modalDelete}>
        Are you sure you want to delete this stock?
      </ModalDelete>
    </React.Fragment>
  );
}
