import * as React from 'react';
import { Button, Card, Col, Dropdown, DropdownButton, Form, Table } from 'react-bootstrap';
import { PencilSquare, Trash3 } from 'react-bootstrap-icons';

import { InputNumber } from '@/components/input-number';
import { ModalDelete } from '@/components/modal-delete';
import { ModalForm } from '@/components/modal-form';
import { SelectorAPISimple } from '@/components/selector-api-simple';
import { Expense, Service, Stock } from '@/helper/interfaces';
import { formatMoney } from '@/helper/utility';

interface Properties {
  id: number;
  value: Value;
  baseURL: string;
  disabled?: boolean;
}
interface Value {
  expenses: Expense[];
  services: Service[];
  stock: Stock[];
}

export function CustomPrices({ id, value, baseURL, disabled = false }: Properties) {
  const [editExpense, setEditExpense] = React.useState<Expense | undefined>();
  const [editService, setEditService] = React.useState<Service | undefined>();
  const [editStock, setEditStock] = React.useState<Stock | undefined>();
  const [putId, setPutId] = React.useState<number | undefined>();

  const modalExpense = React.useRef(null);
  const modalExpenseDelete = React.useRef(null);
  const modalService = React.useRef(null);
  const modalServiceDelete = React.useRef(null);
  const modalStock = React.useRef(null);
  const modalStockDelete = React.useRef(null);

  function openExpenseModal(expense?: Expense | undefined) {
    setEditExpense(expense);
    setPutId(expense?.id);
    modalExpense.current.openModal();
  }
  function openExpenseModalDelete(expense: Expense) {
    setEditExpense(expense);
    setPutId(expense.id);
    modalExpenseDelete.current.openModal();
  }

  function openServiceModal(service?: Service | undefined) {
    setEditService(service);
    setPutId(service?.id);
    modalService.current.openModal();
  }
  function openServiceModalDelete(service: Service) {
    setEditService(service);
    setPutId(service.id);
    modalServiceDelete.current.openModal();
  }

  function openStockModal(stock?: Stock | undefined) {
    setEditStock(stock);
    setPutId(stock?.id);
    modalStock.current.openModal();
  }
  function openStockModalDelete(stock: Stock) {
    setEditStock(stock);
    setPutId(stock.id);
    modalStockDelete.current.openModal();
  }

  return (
    <React.Fragment>
      <Card className='mt-3'>
        <Card.Body>
          <Card.Title className='clearfix'>
            Custom Prices
            <DropdownButton title='+ Add' size='sm' className='float-end' disabled={disabled}>
              <Dropdown.Item onClick={() => openExpenseModal()}>Expense</Dropdown.Item>
              <Dropdown.Item onClick={() => openServiceModal()}>Service</Dropdown.Item>
              <Dropdown.Item onClick={() => openStockModal()}>Stock</Dropdown.Item>
            </DropdownButton>
          </Card.Title>
          <Table striped size='sm'>
            <thead>
              <tr>
                <th>Type </th>
                <th>Rate/Cost/Markup</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {value.services?.map((service) => {
                return (
                  <tr key={service.id}>
                    <td>{service.type.name}</td>
                    <td>{formatMoney(service.rate)}/hr</td>
                    <td className='text-end'>
                      <React.Fragment>
                        <Button
                          variant='link'
                          className='btn-icon me-1'
                          onClick={() => openServiceModal(service)}
                          disabled={disabled}
                        >
                          <PencilSquare aria-label='edit' />
                        </Button>
                        <Button
                          variant='link'
                          className='btn-icon me-1'
                          onClick={() => openServiceModalDelete(service)}
                          disabled={disabled}
                        >
                          <Trash3 area-label='delete' />
                        </Button>
                      </React.Fragment>
                    </td>
                  </tr>
                );
              })}
              {value.stock?.map((st) => {
                return (
                  <tr key={st.id}>
                    <td>{st.type.name}</td>
                    <td>{formatMoney(st.cost)}</td>
                    <td className='text-end'>
                      <React.Fragment>
                        <Button
                          variant='link'
                          className='btn-icon me-1'
                          onClick={() => openStockModal(st)}
                          disabled={disabled}
                        >
                          <PencilSquare aria-label='edit' />
                        </Button>
                        <Button
                          variant='link'
                          className='btn-icon me-1'
                          onClick={() => openStockModalDelete(st)}
                          disabled={disabled}
                        >
                          <Trash3 area-label='delete' />
                        </Button>
                      </React.Fragment>
                    </td>
                  </tr>
                );
              })}
              {value.expenses?.map((expense) => {
                return (
                  <tr key={expense.id}>
                    <td>{expense.type.name}</td>
                    <td>{expense.markup}&#37;</td>
                    <td className='text-end'>
                      <Button
                        variant='link'
                        className='btn-icon me-1'
                        onClick={() => openExpenseModal(expense)}
                        disabled={disabled}
                      >
                        <PencilSquare aria-label='edit' />
                      </Button>
                      <Button
                        variant='link'
                        className='btn-icon me-1'
                        onClick={() => openExpenseModalDelete(expense)}
                        disabled={disabled}
                      >
                        <Trash3 area-label='delete' />
                      </Button>
                    </td>
                  </tr>
                );
              })}
              {value.expenses?.length === 0 && value.services?.length === 0 && value.stock?.length === 0 && (
                <tr>
                  <td colSpan={3}>None</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <ModalForm
        title={(putId ? 'Edit' : 'Add') + ' Expense'}
        url={baseURL + '/expense'}
        putId={putId}
        ref={modalExpense}
        draggable
      >
        {putId && (
          <Col>
            <Form.Control
              type='text'
              name='typeName'
              className='col-12'
              disabled={true}
              value={editExpense?.type.name}
            />
          </Col>
        )}
        {!putId && (
          <React.Fragment>
            <Form.Control type='hidden' name='id' value={id} />
            <SelectorAPISimple name='type' value={editExpense?.id} fetchURL={baseURL + '/expense/types/' + id} />
          </React.Fragment>
        )}
        <InputNumber name='markup' value={editExpense?.markup?.toString()} percentage />
      </ModalForm>

      <ModalDelete title='Delete Expense' url={baseURL + '/expense'} deleteId={putId} ref={modalExpenseDelete}>
        Are you sure you want to delete this expense?
      </ModalDelete>

      <ModalForm
        title={(putId ? 'Edit' : 'Add') + ' Service'}
        url={baseURL + '/service'}
        putId={putId}
        ref={modalService}
        draggable
      >
        {putId && (
          <Col>
            <Form.Control
              type='text'
              name='typeName'
              className='col-12'
              disabled={true}
              value={editService?.type.name}
            />
          </Col>
        )}
        {!putId && (
          <React.Fragment>
            <Form.Control type='hidden' name='id' value={id} />
            <SelectorAPISimple name='type' value={editService?.id} fetchURL={baseURL + '/service/types/' + id} />
          </React.Fragment>
        )}
        <InputNumber name='rate' value={editService?.rate.toString()} money perHour />
      </ModalForm>
      <ModalDelete title='Delete Service' url={baseURL + '/service'} deleteId={putId} ref={modalServiceDelete}>
        Are you sure you want to delete this service?
      </ModalDelete>

      <ModalForm
        title={(putId ? 'Edit' : 'Add') + ' Stock'}
        url={baseURL + '/stock'}
        putId={putId}
        ref={modalStock}
        draggable
      >
        {putId && (
          <Col>
            <Form.Control type='text' name='typeName' className='col-12' disabled={true} value={editStock?.type.name} />
          </Col>
        )}
        {!putId && (
          <React.Fragment>
            <Form.Control type='hidden' name='id' value={id} />
            <SelectorAPISimple name='type' value={editStock?.id} fetchURL={baseURL + '/stock/types/' + id} />
          </React.Fragment>
        )}
        <InputNumber name='cost' value={editStock?.cost?.toString()} money />
      </ModalForm>
      <ModalDelete title='Delete Stock' url={baseURL + '/stock'} deleteId={putId} ref={modalStockDelete}>
        Are you sure you want to delete this stock?
      </ModalDelete>
    </React.Fragment>
  );
}
