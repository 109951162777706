import * as React from 'react';
import { Form, InputGroup, Spinner } from 'react-bootstrap';

import { getAll } from '@/helper/apicalls';
import { PatcherContext } from '@/helper/context';
import { camelToTitle } from '@/helper/utility';

interface Properties {
  name: string;
  displayName?: string;
  size?: string;
  fetchURL: string;
  inactive: boolean;
}

export function PatcherAPISelector({ name, displayName, size = 'col-12', fetchURL, inactive = false }: Properties) {
  displayName = displayName || camelToTitle(name);
  const { onChange, values, disabled } = React.useContext(PatcherContext);

  const [selectList, setSelectList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const nameId = name + 'Id';

  React.useEffect(() => {
    async function getdata() {
      setSelectList(await getAll(fetchURL));
    }
    getdata().then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      <Form.Group controlId={name} className={`mt-3 ${size}`}>
        {isLoading && (
          <div className='select-placeholder'>
            Loading...
            <span className='spinner-container'>
              <Spinner size='sm' animation='border' />
            </span>
          </div>
        )}
        <InputGroup>
          <InputGroup.Text>{displayName}</InputGroup.Text>
          <Form.Select
            name={nameId}
            // eslint-disable-next-line security/detect-object-injection
            value={values[nameId]}
            disabled={selectList.length === 0 || disabled || inactive}
            onChange={onChange}
            className={isLoading ? 'd-none' : ''}
          >
            <option value='0' disabled hidden>
              --Select a {displayName}--
            </option>
            {selectList.map((select) => (
              <option key={select.id} value={select.id}>
                {select.name}
              </option>
            ))}
          </Form.Select>
        </InputGroup>
      </Form.Group>
    </React.Fragment>
  );
}
