import * as React from 'react';
import { Col, Form } from 'react-bootstrap';

import { FormContext } from '@/helper/context';
import { camelToTitle } from '@/helper/utility';

interface Properties {
  name: string;
  value?: boolean;
  valueSelect?: boolean;
  setValue?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Switch({ name, value, valueSelect, setValue }: Properties) {
  const displayName = camelToTitle(name);
  const [checked, setChecked] = React.useState((valueSelect ?? value) || false);
  const { changeTracker, setWasChanged, disabled } = React.useContext(FormContext);

  React.useEffect(() => {
    const didChange = valueSelect ? (valueSelect == value ? false : true) : false;
    changeTracker.current = {
      ...changeTracker.current,
      [name]: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }, []);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setChecked(event.target.checked);
    if (setValue) {
      setValue(event.target.checked);
    }

    const didChange = event.target.checked == value ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      [name]: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }

  return (
    <Col className='mt-3'>
      <Form.Check
        type='switch'
        id={name}
        name={name}
        label={displayName}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    </Col>
  );
}
