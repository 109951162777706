import * as React from 'react';
import { Form } from 'react-bootstrap';

import { Input } from '@/components/input';
import { InputMultiple } from '@/components/input-multiple';
import { InputPhone } from '@/components/input-phone';
import { InputTextArea } from '@/components/input-textarea';
import { SelectorAPI } from '@/components/selector-api';
import { SelectorList } from '@/components/selector-list';
import { Company, Contact, Project } from '@/helper/interfaces';
import { allCountries, allStates } from '@/helper/lists';
import { useStore } from '@/store';

interface CompanyProperties {
  data?: Company;
}
export function CompanyForm({ data }: CompanyProperties) {
  return (
    <React.Fragment>
      <Input name='name' size='col-12' value={data?.name} />
      <Input name='email' size='col-6' value={data?.email} />
      <InputPhone size='col-6' value={data?.phone} />
      <Input name='address' size='col-12' value={data?.address} />
      <Input name='city' size='col-6' value={data?.city} />
      <SelectorList name='state' list={allStates} size='col-4' value={data?.state} />
      <Input name='zip' size='col-2' value={data?.zip} />
      <SelectorList name='country' list={allCountries} size='col-12' value={data?.country} />
      <InputTextArea name='notes' size='col-12' value={data?.notes} />
    </React.Fragment>
  );
}

interface ContactProperties {
  data?: Contact;
}
export function ContactForm({ data }: ContactProperties) {
  return (
    <React.Fragment>
      <Input name='firstName' size='col-6' value={data?.firstName} />
      <Input name='lastName' size='col-6' value={data?.lastName} />
      <Input name='title' size='col-12' value={data?.title} />
      <InputMultiple name='emails' displayName='Email' size='col-6' value={data?.emails} />
      <InputPhone size='col-6' value={data?.phone} />
      <Input name='address' size='col-12' value={data?.address} />
      <Input name='city' size='col-6' value={data?.city} />
      <SelectorList name='state' list={allStates} size='col-4' value={data?.state} />
      <Input name='zip' size='col-2' value={data?.zip} />
      <SelectorList name='country' list={allCountries} size='col-12' value={data?.country} />
      <InputTextArea name='notes' size='col-12' value={data?.notes} />
    </React.Fragment>
  );
}

interface ProjectProperties {
  data?: Project;
  companyId?: number;
}
export function ProjectForm({ data, companyId }: ProjectProperties) {
  const { user } = useStore();
  return (
    <React.Fragment>
      <Input name='name' value={data?.name} />
      {companyId ? (
        <Form.Control type='hidden' name='companyId' value={companyId} />
      ) : (
        <SelectorAPI name='company' value={data?.company?.id} fetchURL='/companies' />
      )}
      <InputTextArea name='notes' value={data?.notes} />
      {user?.role?.privateNotes && (
        <InputTextArea name='privateNotes' displayName='Producer Notes' value={data?.privateNotes} />
      )}
    </React.Fragment>
  );
}
