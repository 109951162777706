/* eslint-disable security/detect-object-injection */
import * as React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import { FormContext } from '@/helper/context';
import { camelToTitle } from '@/helper/utility';

interface Properties {
  name: string;
  size: string;
  value: number;
}

export function InputTimeDuration({ name, size, value }: Properties) {
  const displayName = camelToTitle(name);
  const [valueState, setValueState] = React.useState(value || '');
  const { changeTracker, setWasChanged, disabled, errors } = React.useContext(FormContext);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValueState(Math.round(Number.parseFloat(event.target.value) * 4) / 4);

    const didChange = Number.parseFloat(event.target.value) == value ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      [event.target.name]: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }

  return (
    <Form.Group controlId={name} className={`mt-3 ${size}`}>
      <Form.Label>{displayName}</Form.Label>
      <InputGroup hasValidation>
        <Form.Control
          type='number'
          min='0'
          step='0.25'
          name={name}
          disabled={disabled}
          value={valueState}
          onChange={onChange}
          isInvalid={errors[name] ? true : false}
        />
        <InputGroup.Text>Hour(s)</InputGroup.Text>
        <Form.Control.Feedback type='invalid'>{errors[name]}</Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
}
