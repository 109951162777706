import * as React from 'react';
import { Form } from 'react-bootstrap';

import { FormContext } from '@/helper/context';
import { camelToTitle } from '@/helper/utility';

interface Properties {
  name: string;
  list: List[];
  size?: string;
  value?: string;
  valueSelect?: string;
}
interface List {
  key: string;
  value: string;
  default?: boolean;
}

export function SelectorList({ name, list, size = 'col-12', value, valueSelect }: Properties) {
  const displayName = camelToTitle(name);
  const [valueState, setValueState] = React.useState(
    (valueSelect ?? value) || list.find((item) => item?.default === true)?.key
  );
  const { changeTracker, setWasChanged, disabled, errors } = React.useContext(FormContext);

  React.useEffect(() => {
    const didChange = valueSelect ? (valueSelect == value ? false : true) : false;
    changeTracker.current = {
      ...changeTracker.current,
      [name]: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }, []);

  function onChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setValueState(event.target.value);

    const didChange = event.target.value == value ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      [name]: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }

  return (
    <Form.Group controlId={name} className={'mt-3 ' + size}>
      <Form.Label>{displayName}</Form.Label>
      {/* eslint-disable security/detect-object-injection */}
      <Form.Select
        name={name}
        disabled={disabled}
        value={valueState}
        onChange={onChange}
        isInvalid={errors[name] ? true : false}
      >
        <option></option>
        {list.map((item) => (
          <option key={item.key} value={item.key}>
            {item.value}
          </option>
        ))}
      </Form.Select>
      {/* eslint-disable security/detect-object-injection */}
      <Form.Control.Feedback type='invalid'>{errors[name]}</Form.Control.Feedback>
    </Form.Group>
  );
}
