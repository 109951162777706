import axios from 'axios';

const api_url = process.env.API_URL;

export const api = axios.create();

interface Tokens {
  accessToken: string | undefined;
  refreshToken: string | undefined;
}

api.interceptors.request.use(
  (config) => {
    config.baseURL = api_url;
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
    return config;
  },
  (error) => {
    throw error;
  },
  { synchronous: true }
);

// global scope to ensure only one refresh request sent at a time
// eslint-disable-next-line unicorn/no-useless-undefined
let refreshBuffer: Promise<Tokens> | undefined = undefined;
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && tokensExist() && !originalRequest.sent) {
      // prevent loop if 401 received again
      originalRequest.sent = true;

      // do not refresh if already refreshing
      if (!refreshBuffer) refreshBuffer = refreshTokens();
      const newTokens: Tokens = await refreshBuffer;
      refreshBuffer = undefined;

      if (newTokens?.accessToken) {
        originalRequest.headers = {
          ...originalRequest.headers,
          authorization: `Bearer ${newTokens?.accessToken}`
        };
      }
      return axios(originalRequest);
    } else {
      throw error;
    }
  }
);

function tokensExist() {
  const accessToken = window.localStorage.getItem('accessToken');
  const refreshToken = window.localStorage.getItem('refreshToken');
  return accessToken && refreshToken ? true : false;
}

function refreshTokens() {
  const body = { refreshToken: window.localStorage.getItem('refreshToken') };

  return api
    .post('/auth/refresh', body)
    .then((response) => {
      if (response.data.data?.accessToken && response.data.data?.refreshToken) {
        window.localStorage.setItem('accessToken', response.data.data.accessToken);
        window.localStorage.setItem('refreshToken', response.data.data.refreshToken);
        return { accessToken: response.data.data.accessToken, refreshToken: response.data.data.refreshToken };
      } else {
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('refreshToken');
        return { accessToken: undefined, refreshToken: undefined };
      }
    })
    .catch(() => {
      window.localStorage.removeItem('accessToken');
      window.localStorage.removeItem('refreshToken');
      return { accessToken: undefined, refreshToken: undefined };
    });
}
