import * as React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <Row className='mt-5'>
      <Col xs={6} className='ms-auto me-auto'>
        <Alert variant='danger'>
          404! This page does not exist. <Link to='/'>Go to main page.</Link>
        </Alert>
      </Col>
    </Row>
  );
}
