import * as React from 'react';
import { Table } from 'react-bootstrap';
import { useLoaderData } from 'react-router-dom';

import { ListLink } from '@/components/listlink';
import { Pager } from '@/components/pager';
import { SearchReset } from '@/components/search-reset';
import { Sorter } from '@/components/sorter';
import { getList } from '@/helper/apicalls';
import { ProjectListResponse } from '@/helper/interfaces';

import { baseURL, settings } from './const';

export async function listLoader({ request }: any) {
  const parameters = new URL(request.url).searchParams;
  return getList(baseURL, parameters, settings);
}

export function List() {
  const { data, currentPage, numberOfPages, orderBy, order } = useLoaderData() as ProjectListResponse;
  return (
    <React.Fragment>
      <Table striped bordered hover size='sm'>
        <thead>
          <tr>
            <Sorter name='name' orderBy={orderBy} order={order} />
            <Sorter name='company' orderBy={orderBy} order={order} />
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data?.map((d) => (
              <tr key={d.id}>
                <ListLink to={baseURL + '/' + d.id}>{d.name}</ListLink>
                <ListLink to={'/companies/' + d.company.id}>{d.company.name}</ListLink>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={2}>None</td>
            </tr>
          )}
        </tbody>
      </Table>
      <SearchReset />
      <Pager currentPage={currentPage} numberOfPages={numberOfPages} />
    </React.Fragment>
  );
}
