import * as React from 'react';
import { Pagination } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

interface Properties {
  currentPage: number;
  numberOfPages: number;
  numberOfPagesShown?: number;
}

export function Pager({ currentPage, numberOfPages, numberOfPagesShown = 10 }: Properties) {
  const [parameters, setParameters] = useSearchParams();
  function handlePageClick(page: number) {
    parameters.set('p', String(page));
    setParameters(parameters);
  }

  let firstPageToShow, lastPageToShow;
  if (numberOfPages > numberOfPagesShown) {
    firstPageToShow =
      currentPage - Math.ceil(numberOfPagesShown / 2) > 0 ? Math.ceil(currentPage - numberOfPagesShown / 2) : 1;
    lastPageToShow =
      firstPageToShow + numberOfPagesShown - 1 < numberOfPages
        ? firstPageToShow + numberOfPagesShown - 1
        : numberOfPages;
    if (lastPageToShow == numberOfPages) {
      firstPageToShow = lastPageToShow - numberOfPagesShown + 1;
    }
  } else {
    firstPageToShow = 1;
    lastPageToShow = numberOfPages;
  }

  const active = currentPage;
  const pageLinks = [];
  for (let page = firstPageToShow; page <= lastPageToShow; page++) {
    pageLinks.push(
      <Pagination.Item key={page} active={page === active} onClick={() => handlePageClick(page)}>
        {page}
      </Pagination.Item>
    );
  }

  return (
    <React.Fragment>
      {numberOfPages > 1 && (
        <Pagination className='justify-content-center'>
          {currentPage !== 1 && (
            <React.Fragment>
              <Pagination.First onClick={() => handlePageClick(1)} />
              <Pagination.Prev onClick={() => handlePageClick(currentPage - 1)} />
            </React.Fragment>
          )}
          {numberOfPages > numberOfPagesShown && firstPageToShow > 1 && <Pagination.Ellipsis />}
          {pageLinks}
          {numberOfPages > numberOfPagesShown && lastPageToShow < numberOfPages && <Pagination.Ellipsis />}
          {currentPage !== numberOfPages && (
            <React.Fragment>
              <Pagination.Next onClick={() => handlePageClick(currentPage + 1)} />
              <Pagination.Last onClick={() => handlePageClick(numberOfPages)} />
            </React.Fragment>
          )}
        </Pagination>
      )}
    </React.Fragment>
  );
}
