import * as React from 'react';
import { Button, Col } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import { Link, useLocation } from 'react-router-dom';

import { ModalDelete } from '@/components/modal-delete';
import { ModalForm } from '@/components/modal-form';
import { SelectorAPI } from '@/components/selector-api';
import { Company, Contact } from '@/helper/interfaces';
import { camelToTitle } from '@/helper/utility';

import { SelectorAPIHelper } from './selector-api-helper';

interface Properties {
  name: 'company' | 'contact';
  baseURL: string;
  fetchURL: string;
  linkURL?: string;
  id: number;
  value?: Company[] | Contact[];
  disabled?: boolean;
}

interface Element {
  tokenId?: number;
}

export function InputTokens({ name, baseURL, fetchURL, linkURL, id, value, disabled = false }: Properties) {
  const displayName = camelToTitle(name);

  const location = useLocation();
  const from = location.pathname + location.search;

  const modal = React.useRef(null);
  const modalRemove = React.useRef(null);
  const groupHelper = React.useRef(null);
  const [elementSelect, setElementSelect] = React.useState<Element>();

  function openTokenModal() {
    setElementSelect({});
    modal.current.openModal();
  }

  function addToken() {
    if (name == 'contact') {
      groupHelper.current.addContact();
    } else if (name == 'company') {
      groupHelper.current.addCompany();
    }
  }

  function saveForm() {
    setElementSelect({
      tokenId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="' + name + 'Id"]').value, 10)
    });
  }

  return (
    <React.Fragment>
      <Col xs={12}>
        {value?.map((item) => (
          <span className='me-2 mb-2 token' key={item.id}>
            {linkURL && (
              <Link to={linkURL + '/' + item.id} state={{ from: from }}>
                {item.name}
              </Link>
            )}
            {!linkURL && <React.Fragment>{item.name}</React.Fragment>}
            {!disabled && (
              <a onClick={() => modalRemove.current.openModal(item.id)}>
                <X className='align-x ms-1' aria-label='remove' />
              </a>
            )}
          </span>
        ))}
        {value?.length === 0 && <span className='me-2 mb-2 token'>None</span>}
        <Button
          type='button'
          onClick={openTokenModal}
          disabled={disabled}
          variant='outline-secondary'
          className='me-2 mb-2 token'
        >
          + Add
        </Button>
      </Col>

      <ModalForm title={'Add ' + displayName} url={baseURL} putId={id} ref={modal} draggable>
        <SelectorAPI name={name} fetchURL={fetchURL} valueSelect={elementSelect?.tokenId} addToken={addToken} />
      </ModalForm>

      <SelectorAPIHelper
        elementSelect={elementSelect}
        setElementSelect={setElementSelect}
        saveForm={saveForm}
        modal={modal}
        ref={groupHelper}
      />

      <ModalDelete title={'Remove ' + displayName} url={baseURL} button='Remove' deleteId={id} ref={modalRemove}>
        Are you sure you want to remove this {name}?
      </ModalDelete>
    </React.Fragment>
  );
}
