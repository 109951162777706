import * as React from 'react';
import { Form } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

export function SearchBar() {
  const [parameters, setParameters] = useSearchParams();
  const search = parameters.get('s') || '';

  // prevent enter key from reloading page
  function handleSearch(event: React.FormEvent) {
    event.preventDefault();
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const input = event.target.value;
    let shouldReplace = false;

    // only add to location history on first letter
    if (input.length > 1) shouldReplace = true;
    parameters.set('s', input);
    parameters.delete('p');
    setParameters(parameters, { replace: shouldReplace });
  }

  return (
    <Form noValidate className='ms-auto' onSubmit={handleSearch}>
      <div className='col-12'>
        <Form.Label className='visually-hidden' htmlFor='search'>
          Search
        </Form.Label>
        <Form.Group className='mb-2'>
          <Form.Control id='search' type='search' placeholder='Search' value={search} onChange={handleChange} />
        </Form.Group>
      </div>
    </Form>
  );
}
