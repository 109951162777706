import { addDays, isAfter } from 'date-fns';
import * as React from 'react';
import { Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import { FormContext } from '@/helper/context';

import { CustomInput } from './datepicker-custom';

interface Properties {
  value?: Times;
  noChangeTracker?: boolean;
}
interface Times {
  startDate: string;
  endDate: string;
}

export function SelectorDateRange({ value, noChangeTracker = false }: Properties) {
  const { changeTracker, setWasChanged, disabled, errors } = React.useContext(FormContext);

  const yesterday = addDays(new Date().setHours(0, 0, 0, 0), -1);
  const [endDate, setEndDate] = React.useState(value?.endDate ? new Date(value?.endDate) : yesterday);
  const [startDate, setStartDate] = React.useState(
    value?.startDate ? new Date(value?.startDate) : addDays(yesterday, -14)
  );
  const maxDate = yesterday;

  function setStart(date: Date) {
    if (date) {
      setStartDate(date);
      if (isAfter(date, endDate)) setEndDate(addDays(date, 1));

      if (!noChangeTracker) {
        const didChange = date?.toISOString() == value?.startDate ? false : true;
        changeTracker.current = {
          ...changeTracker.current,
          startTime: didChange
        };
        setWasChanged(Object.values(changeTracker.current).includes(true));
      }
    }
  }

  function setEnd(date: Date) {
    if (date) {
      setEndDate(date);
      if (isAfter(startDate, date)) setStartDate(addDays(date, -1));

      if (!noChangeTracker) {
        const didChange = date?.toISOString() == value?.endDate ? false : true;
        changeTracker.current = {
          ...changeTracker.current,
          endTime: didChange
        };
        setWasChanged(Object.values(changeTracker.current).includes(true));
      }
    }
  }

  return (
    <React.Fragment>
      <Row className='justify-content-center'>
        <Form.Control type='hidden' name='startDate' value={startDate?.toISOString()} />
        <Form.Control type='hidden' name='endDate' value={endDate?.toISOString()} />
        <Form.Group className='col-12'>
          <span className={'d-block form-label' + (errors?.startTime ? ' is-invalid' : '')}>Date Range</span>
          <DatePicker
            selected={startDate}
            disabled={disabled}
            onChange={(date) => setStart(date)}
            maxDate={maxDate}
            dateFormat='MMM do'
            customInput={<CustomInput id='startTimeSelector' disabled={disabled} />}
          />
          &nbsp;-&nbsp;
          <DatePicker
            selected={endDate}
            disabled={disabled}
            onChange={(date) => setEnd(date)}
            minDate={startDate}
            maxDate={maxDate}
            dateFormat='MMM do'
            customInput={<CustomInput id='endTimeSelector' disabled={disabled} />}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.startTime}
            {errors.endTime}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
    </React.Fragment>
  );
}
