import { patchSingle } from '@/helper/apicalls';

import { baseURL } from './const';

export async function payAction({ request, params }: any) {
  const body = Object.fromEntries(await request.formData());

  if (body.id) {
    body.userId = JSON.parse(body.id);
    delete body.id;
  }
  if (body.rate) body.rate = JSON.parse(body.rate);
  if (body.percentage) body.percentage = JSON.parse(body.percentage);

  body.usePercentage = body.usePercentage == 'on' ? true : false;

  const URL = baseURL + '/pay';
  // eslint-disable-next-line unicorn/prefer-ternary
  if (request.method == 'PUT') {
    return patchSingle(URL, params.id, body);
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}
