import * as React from 'react';
import { Button, Card, Col, Form, Table } from 'react-bootstrap';
import { PencilSquare, Trash3 } from 'react-bootstrap-icons';

import { InputNumber } from '@/components/input-number';
import { ModalDelete } from '@/components/modal-delete';
import { ModalForm } from '@/components/modal-form';
import { SelectorAPISimple } from '@/components/selector-api-simple';
import { Switch } from '@/components/switch';
import { Service } from '@/helper/interfaces';
import { formatMoney } from '@/helper/utility';

interface Properties {
  id: number;
  value: Value;
  baseURL: string;
  disabled?: boolean;
}

interface Value {
  services: Service[];
  rate: number;
  usePercentage: boolean;
  percentage: number;
}

export function CustomPay({ id, value, baseURL, disabled = false }: Properties) {
  const [editService, setEditService] = React.useState<Service | undefined>();
  const [putId, setPutId] = React.useState<number | undefined>();
  const [usePercentage, setUsePercentage] = React.useState<boolean>();

  const modalDefault = React.useRef(null);
  const modalService = React.useRef(null);
  const modalServiceDelete = React.useRef(null);

  function openDefaultModal() {
    setUsePercentage(value?.usePercentage);
    modalDefault.current.openModal();
  }

  function openServiceModal(service?: Service | undefined) {
    setEditService(service);
    setPutId(service?.id);
    setUsePercentage(service?.usePercentage);
    modalService.current.openModal();
  }
  function openServiceModalDelete(service: Service) {
    setEditService(service);
    setPutId(service?.id);
    modalServiceDelete.current.openModal();
  }

  return (
    <React.Fragment>
      <Card className='mt-3'>
        <Card.Body>
          <Card.Title className='clearfix'>
            Mixer Pay Rates
            <Button size='sm' className='float-end' disabled={disabled} onClick={() => openServiceModal()}>
              + Add
            </Button>
          </Card.Title>
          <Table striped size='sm'>
            <thead>
              <tr>
                <th>Type</th>
                <th>Percentage</th>
                <th>Rate</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Default</td>
                <td>{value.usePercentage ? value.percentage + '%' : ''}</td>
                <td>{formatMoney(value.rate) + '/hr' + (value.usePercentage ? ' min' : '')}</td>
                <td className='text-end'>
                  <Button variant='link' className='btn-icon me-1' onClick={openDefaultModal} disabled={disabled}>
                    <PencilSquare aria-label='edit' />
                  </Button>
                  <Button variant='link' className='btn-icon me-1' disabled={true}>
                    <Trash3 area-label='delete' />
                  </Button>
                </td>
              </tr>
              {value.services?.map((service) => {
                return (
                  <tr key={service.id}>
                    <td>{service.type.name}</td>
                    <td>{service.usePercentage ? service.percentage + '%' : ''}</td>
                    <td>{formatMoney(service.rate) + '/hr' + (service.usePercentage ? ' min' : '')}</td>
                    <td className='text-end'>
                      <React.Fragment>
                        <Button
                          variant='link'
                          className='btn-icon me-1'
                          onClick={() => openServiceModal(service)}
                          disabled={disabled}
                        >
                          <PencilSquare aria-label='edit' />
                        </Button>
                        <Button
                          variant='link'
                          className='btn-icon me-1'
                          onClick={() => openServiceModalDelete(service)}
                          disabled={disabled}
                        >
                          <Trash3 area-label='delete' />
                        </Button>
                      </React.Fragment>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <ModalForm title='Edit Pay Rate' url={baseURL + '/pay'} putId={id} ref={modalDefault} draggable>
        <Col xs={12}>
          <Form.Label>Type</Form.Label>
          <Form.Control type='text' name='typeName' disabled={true} value='Default' />
        </Col>
        <Switch name='usePercentage' value={usePercentage} setValue={setUsePercentage} />
        {usePercentage && <InputNumber name='percentage' value={value?.percentage?.toString()} percentage />}
        <InputNumber
          name='rate'
          displayName={(usePercentage ? 'Minimum ' : '') + 'Rate'}
          value={value?.rate?.toString()}
          money
          perHour
        />
      </ModalForm>

      <ModalForm
        title={(putId ? 'Edit' : 'Add') + ' Pay Rate'}
        url={baseURL + '/service'}
        putId={putId}
        ref={modalService}
        draggable
      >
        {putId && (
          <Col xs={12}>
            <Form.Label>Type</Form.Label>
            <Form.Control type='text' name='typeName' disabled={true} value={editService?.type.name} />
          </Col>
        )}
        {!putId && (
          <React.Fragment>
            <Form.Control type='hidden' name='id' value={id} />
            <SelectorAPISimple name='type' value={editService?.id} fetchURL={baseURL + '/service/types/' + id} />
          </React.Fragment>
        )}
        <Switch name='usePercentage' value={usePercentage} setValue={setUsePercentage} />
        {usePercentage && <InputNumber name='percentage' value={editService?.percentage?.toString()} percentage />}
        <InputNumber
          name='rate'
          displayName={(usePercentage ? 'Minimum ' : '') + 'Rate'}
          value={editService?.rate?.toString()}
          money
          perHour
        />
      </ModalForm>
      <ModalDelete title='Delete Service' url={baseURL + '/service'} deleteId={putId} ref={modalServiceDelete}>
        Are you sure you want to delete this service?
      </ModalDelete>
    </React.Fragment>
  );
}
