import * as React from 'react';
import { Alert, Container, Nav, Row } from 'react-bootstrap';
import { Outlet, useMatches } from 'react-router-dom';

import { ApiAlert } from '@/components/apialert';
import { ModalForm } from '@/components/modal-form';
import { PageHeader } from '@/components/pageheader';
import { SearchBar } from '@/components/search-bar';
import { SelectorDateRange } from '@/components/selector-daterange';
import { SelectorTableMini } from '@/components/selector-table-mini';
import { Match } from '@/helper/interfaces';
import { useStore } from '@/store';

import { baseURL } from './const';

export function Menu() {
  const { user } = useStore();

  const matches = useMatches() as Match[];
  const isList = matches.at(-1).data?.isList;

  const crumbs = matches
    .filter((match: any) => {
      return Boolean(match.handle?.crumb);
    })
    .map((match: any) => {
      return { name: match.handle?.crumb, link: match.pathname };
    });

  const modal = React.useRef(null);

  return (
    <React.Fragment>
      <Container fluid={isList ? true : false}>
        {user?.role?.reports ? (
          <React.Fragment>
            {isList ? (
              <React.Fragment>
                <PageHeader crumbs={crumbs}>Reports</PageHeader>
                <Nav>
                  <Nav.Link onClick={() => modal.current.openModal()}>Create Mixer Reports</Nav.Link>
                  <SearchBar />
                </Nav>
              </React.Fragment>
            ) : (
              <PageHeader crumbs={crumbs}>Report</PageHeader>
            )}
            <Outlet />
          </React.Fragment>
        ) : (
          <Row className='justify-content-center'>
            <Alert variant='warning' className='col-6 mt-5'>
              You are not authorized to access this page
            </Alert>
          </Row>
        )}
      </Container>

      <ModalForm title='Add User' url={baseURL} ref={modal} draggable>
        <ApiAlert listTitle='WO'>
          Cannot create reports with unfinished work orders!
          <br />
          Finish these work orders first.
        </ApiAlert>
        <SelectorTableMini name='mixer' fetchURL='users/mixers' />
        <SelectorDateRange noChangeTracker />
      </ModalForm>
    </React.Fragment>
  );
}
