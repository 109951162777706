import * as React from 'react';
import { Container, Nav, Navbar, Spinner } from 'react-bootstrap';
import { NavLink, Outlet, useNavigate, useNavigation } from 'react-router-dom';

import { api } from '@/helper/api';
import { useStore } from '@/store';

export default function Root() {
  const navigate = useNavigate();
  const navigation = useNavigation();
  const { user, setUser } = useStore();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    async function getUser() {
      api
        .get('/auth/me')
        .then((response) => {
          setUser(response.data.data);
        })
        .catch((error) => {
          throw new Error(error.response.data.message);
        });
    }
    if (Object.keys(user).length === 0) {
      getUser().then(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  const logout = async () => {
    api
      .get('/auth/logout')
      .then()
      .catch(() => {
        //do nothing
      })
      .finally(() => {
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('refreshToken');
        navigate('/login');
      });
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Spinner animation='border' id='loader' className='loading m-auto' />
      ) : (
        <React.Fragment>
          <Navbar expand='md'>
            <Container fluid>
              <Navbar.Brand>Limetime 2.0</Navbar.Brand>
              <Navbar.Toggle aria-controls='basic-navbar-nav' />
              <Navbar.Collapse id='basic-navbar-nav' role=''>
                <Nav className='me-auto' variant='pills'>
                  <Nav.Link as={NavLink} to='/schedule'>
                    Schedule
                  </Nav.Link>
                  <Nav.Link as={NavLink} to='/workorders'>
                    Work Orders
                  </Nav.Link>
                  {user?.role?.estimates && (
                    <Nav.Link as={NavLink} to='/estimates'>
                      Estimates
                    </Nav.Link>
                  )}
                  {user?.role?.jobs && (
                    <Nav.Link as={NavLink} to='/jobs'>
                      Jobs
                    </Nav.Link>
                  )}
                  {user?.role?.invoices && (
                    <Nav.Link as={NavLink} to='/invoices'>
                      Invoices
                    </Nav.Link>
                  )}
                  <Nav.Link as={NavLink} to='/contacts'>
                    Contacts
                  </Nav.Link>
                  <Nav.Link as={NavLink} to='/companies'>
                    Companies
                  </Nav.Link>
                  <Nav.Link as={NavLink} to='/projects'>
                    Projects
                  </Nav.Link>
                  {user?.role?.users && (
                    <Nav.Link as={NavLink} to='/users'>
                      Users
                    </Nav.Link>
                  )}
                  {user?.role?.reports && (
                    <Nav.Link as={NavLink} to='/reports/mixers'>
                      Reports
                    </Nav.Link>
                  )}
                </Nav>
                <Nav>
                  <Nav.Link onClick={logout}>Logout {user?.name}</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <div className='position-relative'>
            <div id='loader' className={navigation.state === 'loading' ? 'loading' : ''}>
              <Spinner animation='border' />
            </div>
            <div id='fader' className={navigation.state === 'loading' ? 'loading' : ''}>
              <Outlet />
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
