import { patchSingle } from '@/helper/apicalls';

import { baseURL } from './const';

export async function projectAction({ request, params }: any) {
  const body = Object.fromEntries(await request.formData());

  if (body.id) {
    body.projectId = JSON.parse(body.id);
    delete body.id;
  }

  if (request.method == 'PUT') {
    return patchSingle(baseURL + '/project/add', params.id, body);
  } else if (request.method == 'DELETE') {
    return patchSingle(baseURL + '/project/remove', params.id, body);
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}
