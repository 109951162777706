/* eslint-disable security/detect-object-injection */
import * as React from 'react';
import { Form, Spinner } from 'react-bootstrap';

import { getAll } from '@/helper/apicalls';
import { FormContext } from '@/helper/context';
import { Expense, Room, Service } from '@/helper/interfaces';
import { camelToTitle } from '@/helper/utility';

interface Properties {
  name: string;
  displayName?: string;
  size?: string;
  value?: number;
  valueSelect?: number;
  fetchURL: string;
  setValue?: React.Dispatch<React.SetStateAction<Room | Service | Expense>>;
}

export function SelectorAPISimple({
  name,
  displayName,
  size = 'col-12',
  value,
  valueSelect,
  fetchURL,
  setValue
}: Properties) {
  if (!displayName) displayName = camelToTitle(name);
  const nameId = name + 'Id';

  const { changeTracker, setWasChanged, disabled, errors } = React.useContext(FormContext);

  const [selected, setSelected] = React.useState((valueSelect ?? value) || '0');
  const [selectList, setSelectList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    async function getdata() {
      setSelectList(await getAll(fetchURL));
    }
    getdata().then(() => {
      setIsLoading(false);
    });
    const didChange = valueSelect ? (valueSelect?.toString() === value?.toString() ? false : true) : false;
    changeTracker.current = {
      ...changeTracker.current,
      [name]: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }, []);

  function onChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setSelected(event.target.value);

    if (setValue) {
      setValue(selectList.find((select) => select.id.toString() === event.target.value));
    }

    const didChange = event.target.value === value?.toString() ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      [name]: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }

  return (
    <React.Fragment>
      <Form.Group controlId={name} className={`mt-3 ${size}`}>
        <Form.Label>{displayName}</Form.Label>
        {isLoading && (
          <div className='select-placeholder'>
            Loading...
            <span className='spinner-container'>
              <Spinner size='sm' animation='border' />
            </span>
          </div>
        )}
        <Form.Select
          name={nameId}
          value={selected}
          disabled={selectList.length === 0 || disabled}
          onChange={onChange}
          isInvalid={errors[nameId] ? true : false}
          className={isLoading ? 'd-none' : ''}
        >
          <option value='0' disabled hidden>
            --Select a {displayName}--
          </option>
          {selectList.map((select) => (
            <option key={select.id} value={select.id}>
              {select.name}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type='invalid'>{errors[nameId]}</Form.Control.Feedback>
      </Form.Group>
    </React.Fragment>
  );
}
