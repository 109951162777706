/* eslint-disable security/detect-object-injection */
import * as React from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Trash3 } from 'react-bootstrap-icons';

import { FormContext } from '@/helper/context';
import { camelToTitle } from '@/helper/utility';

interface Properties {
  name: string;
  displayName?: string;
  size?: string;
  value?: string[];
  valueSelect?: string[];
}

export function InputMultiple({ name, displayName, size = 'col-12', value, valueSelect }: Properties) {
  if (!displayName) displayName = camelToTitle(name);
  const [valueState, setValueState] = React.useState((valueSelect ?? value) || ['']);
  const { changeTracker, setWasChanged, disabled, errors } = React.useContext(FormContext);

  React.useEffect(() => {
    const didChange = valueSelect ? (valueSelect?.toString() == value?.toString() ? false : true) : false;
    changeTracker.current = {
      ...changeTracker.current,
      [name]: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }, []);

  function onChange(event: React.ChangeEvent, index: number) {
    const castEvent = event as React.ChangeEvent<HTMLInputElement>;
    const newArray = valueState.map((value, innerIndex) => {
      if (innerIndex === index) return castEvent.target.value;
      return value;
    });
    setValueState(newArray);

    const didChange = newArray?.toString() === value?.toString() ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      [name]: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }

  function remove(index: number) {
    const newArray = valueState.filter((value, indexInner) => {
      return index !== indexInner;
    });
    setValueState(newArray.length > 0 ? newArray : ['']);

    const didChange = newArray?.toString() === value?.toString() ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      [name]: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }

  function add() {
    setValueState([...valueState, '']);
  }

  return (
    <React.Fragment>
      <Form.Control type='hidden' name={name} value={JSON.stringify(valueState)} />
      <Form.Group className={`mt-3 ${size}`}>
        <Form.Label>{displayName}</Form.Label>
        <Button variant='outline-primary' type='button' size='sm' className='ms-1 mb-1 btn-xxs' onClick={add}>
          +
        </Button>
        {valueState.map((element, index) => (
          <React.Fragment key={index}>
            <InputGroup className={'mb-1' + (errors[name] || errors[name + index] ? ' is-invalid' : '')}>
              <Form.Control
                type='text'
                name={'multiElement_' + index}
                disabled={disabled}
                value={element}
                onChange={(event) => onChange(event, index)}
                isInvalid={errors[name] || errors[name + index] ? true : false}
                autoComplete='off'
              />
              <Button variant='outline-danger' onClick={() => remove(index)}>
                <Trash3 aria-label='delete' />
              </Button>
            </InputGroup>
            <Form.Control.Feedback type='invalid'>{errors[name + index]}</Form.Control.Feedback>
          </React.Fragment>
        ))}
        <Form.Control.Feedback type='invalid'>{errors[name]}</Form.Control.Feedback>
      </Form.Group>
    </React.Fragment>
  );
}
