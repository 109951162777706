import * as React from 'react';
import { Form } from 'react-bootstrap';
import { useFetcher } from 'react-router-dom';

import { PatcherContext } from '@/helper/context';

interface Properties {
  id: number;
  values: Record<string, any>;
  url: string;
  disabled: boolean;
  children: React.ReactNode;
}

export function Patcher({ id, values, url, disabled, children }: Properties) {
  const fetcher = useFetcher();
  // optimistic ui
  if (fetcher.formData) {
    for (const key in values) {
      // eslint-disable-next-line security/detect-object-injection
      values[key] = fetcher.formData.get(key);
    }
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    let newValues;
    if (event.target.type == 'checkbox') {
      newValues = { ...values, [event.target.name]: event.target.checked };
    } else if (event.target.type == 'select-one') {
      newValues = { ...values, [event.target.name]: event.target.value };
    }

    fetcher.submit(newValues, {
      method: 'patch',
      action: url + '/' + id
    });
  }

  return (
    <PatcherContext.Provider value={{ onChange, values, disabled }}>
      <Form noValidate>{children}</Form>
    </PatcherContext.Provider>
  );
}
