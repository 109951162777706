/* eslint-disable sonarjs/no-duplicate-string */
import { addDays, addMinutes, format, isSameDay, setHours, setMinutes, startOfDay } from 'date-fns';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import { FormContext } from '@/helper/context';

import { CustomInput } from './datepicker-custom';

interface Properties {
  value?: Times;
  valueSelect?: Times;
}
interface Times {
  startTime: string;
  endTime: string;
}

export function SelectorDateTime({ value, valueSelect }: Properties) {
  const { changeTracker, setWasChanged, disabled, errors } = React.useContext(FormContext);

  const getStartTime = valueSelect?.startTime ?? value?.startTime;
  const getEndTime = valueSelect?.endTime ?? value?.endTime;
  const [startTimeState, setStartTimeState] = React.useState(
    getStartTime ? new Date(getStartTime) : new Date(new Date().setHours(10, 0, 0, 0))
  );
  const [endTimeState, setEndTimeState] = React.useState(
    getEndTime ? new Date(getEndTime) : new Date(new Date().setHours(18, 0, 0, 0))
  );
  const [maxDate, setMaxDate] = React.useState(addDays(startTimeState, 1));

  React.useEffect(() => {
    const didChangeStartTime = valueSelect?.startTime
      ? valueSelect?.startTime == value?.startTime
        ? false
        : true
      : false;
    const didChangeEndTime = valueSelect?.endTime ? (valueSelect?.endTime == value?.endTime ? false : true) : false;
    changeTracker.current = {
      ...changeTracker.current,
      startTime: didChangeStartTime,
      endTime: didChangeEndTime
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }, []);

  const initMinTime = isSameDay(startTimeState, endTimeState)
    ? addMinutes(startTimeState, 15)
    : startOfDay(endTimeState);
  const [minTime, setMinTime] = React.useState(initMinTime);

  const [maxTime] = React.useState(setHours(setMinutes(new Date(), 45), 23));

  function setStart(date: Date) {
    if (date) {
      const endTimeOnly = format(endTimeState, 'HH:mm');
      const minEndTime = addMinutes(date, 15);
      let endTime;
      if (isSameDay(startTimeState, endTimeState)) {
        if (format(date, 'HH:mm') > endTimeOnly) {
          endTime = minEndTime;
        } else {
          const [hours, minutes] = endTimeOnly.split(':');
          const endTimeDateObject = setHours(
            setMinutes(new Date(date), Number.parseInt(minutes, 10)),
            Number.parseInt(hours, 10)
          );
          endTime = endTimeDateObject;
        }
        setMinTime(minEndTime);
      } else {
        const [hours, minutes] = endTimeOnly.split(':');
        const endTimeDateObject = setHours(
          setMinutes(new Date(date), Number.parseInt(minutes, 10)),
          Number.parseInt(hours, 10)
        );
        endTime = addDays(endTimeDateObject, 1);
        setMinTime(startOfDay(endTimeDateObject));
      }
      setStartTimeState(date);
      setEndTimeState(endTime);
      setMaxDate(addDays(date, 1));

      const didChangeStartTime = date?.toISOString() == value?.startTime ? false : true;
      const didChangeEndTime = endTime?.toISOString() == value?.endTime ? false : true;
      changeTracker.current = {
        ...changeTracker.current,
        startTime: didChangeStartTime,
        endTime: didChangeEndTime
      };
      setWasChanged(Object.values(changeTracker.current).includes(true));
    }
  }

  function setEnd(date: Date) {
    if (date) {
      if (isSameDay(startTimeState, date)) {
        setMinTime(addMinutes(startTimeState, 15));
      } else {
        setMinTime(startOfDay(date));
      }
      setEndTimeState(date);

      const didChange = date?.toISOString() == value.endTime ? false : true;
      changeTracker.current = {
        ...changeTracker.current,
        endTime: didChange
      };
      setWasChanged(Object.values(changeTracker.current).includes(true));
    }
  }

  return (
    <React.Fragment>
      <Form.Control type='hidden' name='startTime' value={startTimeState?.toISOString()} />
      <Form.Group className='mt-3 col-6'>
        <span className={'d-block form-label' + (errors?.startTime ? ' is-invalid' : '')}>Start Time</span>
        <DatePicker
          selected={startTimeState}
          disabled={disabled}
          onChange={(date) => setStart(date)}
          showTimeSelect
          timeIntervals={15}
          dateFormat='MMM do - h:mm aa'
          customInput={<CustomInput id='startTimeSelector' disabled={disabled} />}
        />
        <Form.Control.Feedback type='invalid'>{errors.startTime}</Form.Control.Feedback>
      </Form.Group>
      <Form.Control type='hidden' name='endTime' value={endTimeState?.toISOString()} />
      <Form.Group className='mt-3 col-6'>
        <span className={'d-block form-label' + (errors?.endTime ? ' is-invalid' : '')}>End Time</span>
        <DatePicker
          selected={endTimeState}
          disabled={disabled}
          onChange={(date) => setEnd(date)}
          minDate={startTimeState}
          maxDate={maxDate}
          minTime={minTime}
          maxTime={maxTime}
          showTimeSelect
          timeIntervals={15}
          timeCaption='Time'
          dateFormat='MMM do - h:mm aa'
          customInput={<CustomInput id='endTimeSelector' disabled={disabled} />}
        />
        <Form.Control.Feedback type='invalid'>{errors.endTime}</Form.Control.Feedback>
      </Form.Group>
    </React.Fragment>
  );
}
