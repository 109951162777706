import { patchSingle } from '@/helper/apicalls';

import { baseURL } from './const';

export async function companyAction({ request, params }: any) {
  const body = Object.fromEntries(await request.formData());

  if (body?.ids?.length > 0) {
    body.companyId = JSON.parse(body.ids)[0];
    delete body.ids;
  } else if (body?.companyId) {
    body.companyId = JSON.parse(body.companyId);
  }

  if (request.method == 'PUT') {
    return patchSingle(baseURL + '/company/add', params.id, body);
  } else if (request.method == 'DELETE') {
    return patchSingle(baseURL + '/company/remove', params.id, body);
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}
