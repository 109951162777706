import * as React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { FileEarmarkPlus } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';

import { checkIfWOExists, postSingle } from '@/helper/apicalls';

interface Properties {
  sessionId: number;
  disableModals: boolean;
  setDisableModals: (isChecking: boolean) => void;
}

export function CreateWOLink({ sessionId, disableModals, setDisableModals }: Properties) {
  const navigate = useNavigate();
  const [modal, setModal] = React.useState(false);
  const [isChecking, setIsChecking] = React.useState(false);
  const [isCreating, setIsCreating] = React.useState(false);

  const location = useLocation();
  const from = location.pathname + location.search;

  async function checkWorkOrder() {
    setIsChecking(true);
    setDisableModals(true);
    await checkIfWOExists(sessionId).then((response) => {
      if (response.data.data.exists) {
        navigate('/workorders/' + response.data.data.id, { state: { from: from } });
      } else if (!response.data.data.exists) {
        setIsChecking(false);
        setDisableModals(false);
        setModal(true);
      }
    });
  }

  async function createWorkOrder() {
    setIsCreating(true);
    await postSingle('/workorders/fromsession', { sessionId: sessionId }).then((response) => {
      navigate('/workorders/' + response.data.data, { state: { from: from } });
    });
  }

  function closeModal() {
    if (!isCreating) {
      setModal(false);
    }
  }

  return (
    <React.Fragment>
      <Button variant='link' type='button' className='btn-icon' onClick={checkWorkOrder} disabled={disableModals}>
        {isChecking && <Spinner size='sm' />}
        {!isChecking && <FileEarmarkPlus aria-label='create work order' />}
      </Button>
      <Modal show={modal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Work Order?</Modal.Title>
        </Modal.Header>
        <Modal.Body>No work order has been created for this session, would you like to create one?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' type='button' className='me-2' onClick={closeModal} disabled={isCreating}>
            Cancel
          </Button>
          <Button variant='success' type='button' onClick={createWorkOrder} disabled={isCreating}>
            {isCreating && (
              <React.Fragment>
                <Spinner size='sm' /> Creating...
              </React.Fragment>
            )}
            {!isCreating && <React.Fragment>Create</React.Fragment>}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
