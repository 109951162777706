import { isBefore, parseISO, set, startOfDay } from 'date-fns';
import * as React from 'react';
import { Alert, Button, Card, Dropdown, DropdownButton } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';
import { useLoaderData, useLocation } from 'react-router-dom';

import { Expenses } from '@/components/expenses';
import { FormButtons } from '@/components/formbuttons';
import { InfoTable } from '@/components/infotable';
import { InputNumber } from '@/components/input-number';
import { InputTextArea } from '@/components/input-textarea';
import { ListLink } from '@/components/listlink';
import { ModalForm } from '@/components/modal-form';
import { Patcher } from '@/components/patcher';
import { PatcherSwitch } from '@/components/patcher-switch';
import { SelectorAPIGroup } from '@/components/selector-api-group';
import { SelectorAPIHelper } from '@/components/selector-api-helper';
import { SelectorDate } from '@/components/selector-date';
import { SelectorList } from '@/components/selector-list';
import { Services } from '@/components/services';
import { Stock } from '@/components/stock';
import { deleteSingle, getSingle, patchSingle, postSingle, putSingle } from '@/helper/apicalls';
import { WorkOrder } from '@/helper/interfaces';
import { allBackups } from '@/helper/lists';
import { formatDate, formatMoney, formatTime } from '@/helper/utility';
import { useStore } from '@/store';

import { baseURL } from './const';

export async function singleLoader({ params }: any) {
  if (Number.isInteger(Number.parseInt(params.id, 10))) {
    return getSingle(baseURL, params.id);
  } else {
    throw new TypeError('404! This is not a valid URL.');
  }
}

export async function singleAction({ request, params }: any) {
  const body = Object.fromEntries(await request.formData());
  const redirectTo = body.redirectTo;
  delete body.redirectTo;

  if (body.companyId) body.companyId = JSON.parse(body.companyId);
  if (body.contactId) body.contactId = JSON.parse(body.contactId);
  if (body.projectId) body.projectId = JSON.parse(body.projectId);

  if (request.method == 'POST') {
    return postSingle(baseURL, body);
  } else if (request.method == 'PUT') {
    return putSingle(baseURL, params.id, body);
  } else if (request.method == 'PATCH') {
    return patchSingle(baseURL, params.id, body);
  } else if (request.method == 'DELETE') {
    return deleteSingle(baseURL, params.id, redirectTo);
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}

export async function infoAction({ request, params }: any) {
  const body = Object.fromEntries(await request.formData());

  // eslint-disable-next-line unicorn/prefer-ternary
  if (request.method == 'PUT') {
    return putSingle(baseURL + '/info', params.id, body);
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function Single() {
  const { user } = useStore();
  const data = useLoaderData() as WorkOrder;

  const location = useLocation();
  const [redirectTo] = React.useState(location.state?.from || baseURL);

  const disabled =
    data?.deleted || data?.job?.deleted || (data?.invoiceId ? true : false) || !user?.role?.editWorkOrders;
  const disabledManager =
    !user?.role?.manageWorkOrders &&
    data.isFinished &&
    isBefore(startOfDay(parseISO(data.date)), startOfDay(new Date()));

  const unusedTimeUnits = data.unusedTime > 1 ? ' hrs' : ' hr';

  const modal = React.useRef(null);
  const modalInfo = React.useRef(null);
  const modalPrice = React.useRef(null);
  const groupHelper = React.useRef(null);
  const [elementSelect, setElementSelect] = React.useState<Partial<WorkOrder>>();

  function editWorkOrder() {
    setElementSelect({});
    modal.current.openModal();
  }

  function saveForm() {
    setElementSelect({
      companyId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="companyId"]').value, 10),
      contactId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="contactId"]').value, 10),
      projectId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="projectId"]').value, 10),
      date: document.querySelector<HTMLInputElement>('input[name="date"]').value
    });
  }

  return (
    <React.Fragment>
      {data.deleted && (
        <Alert variant='danger' className='mt-3'>
          This work order was deleted and can no longer be edited.
        </Alert>
      )}
      {data?.job?.deleted && !data.deleted && (
        <Alert variant='danger' className='mt-3'>
          The parent&nbsp;
          <ListLink to={'/jobs/' + data.job.id} nowrapper>
            Job {data.job.id}
          </ListLink>
          &nbsp;was deleted so this work order can no longer be edited.
        </Alert>
      )}
      {data?.invoiceId && (
        <Alert variant='warning' className='mt-3'>
          This work order was added to&nbsp;
          <ListLink to={'/invoices/' + data.invoiceId} nowrapper>
            Inv {data.invoiceId}
          </ListLink>
          &nbsp;and can not longer be edited
        </Alert>
      )}
      {data.isFinished && !data.invoiceId && (
        <Alert variant='warning' className='mt-3'>
          Work orders that have been finished can no longer be edited.
        </Alert>
      )}
      {!user?.role?.editWorkOrders && !data.deleted && !data.company.deleted && (
        <Alert variant='warning' className='mt-3'>
          You are not authorized to edit this page.
        </Alert>
      )}

      <Card>
        <Card.Body>
          <Card.Title className='clearfix'>
            Work Order #{data.id}
            {!data?.session && user?.role?.manageWorkOrders ? (
              <DropdownButton
                title={
                  <React.Fragment>
                    <PencilSquare className='icon-align' /> Edit
                  </React.Fragment>
                }
                size='sm'
                className='float-end'
                disabled={disabled}
              >
                <Dropdown.Item onClick={editWorkOrder}>Work Order</Dropdown.Item>
                <Dropdown.Item onClick={() => modalInfo.current.openModal()}>Titles and Technical Data</Dropdown.Item>
              </DropdownButton>
            ) : (
              <Button
                type='button'
                size='sm'
                className='float-end'
                onClick={() => modalInfo.current.openModal()}
                disabled={disabled || data.isFinished}
              >
                <PencilSquare className='icon-align' /> Titles and Technical Data
              </Button>
            )}
          </Card.Title>
          <InfoTable
            elements={[
              { title: 'Company', content: data.company.name, link: '/companies/' + data.company.id },
              { title: 'Contact', content: data.contact.name, link: '/contacts/' + data.contact.id },
              { title: 'Project', content: data.project.name, link: '/projects/' + data.project.id },
              ...(data.session
                ? [
                    { title: 'Mixer', content: data.session.mixer.name, link: '/users/' + data.session.mixer.id },
                    {
                      title: 'Session',
                      content:
                        formatDate(data.session?.startTime) +
                        ' ' +
                        formatTime(data.session?.startTime) +
                        ' - ' +
                        formatTime(data.session?.endTime) +
                        ' in Studio ' +
                        data.session?.room?.name
                    }
                  ]
                : [{ title: 'Date', content: formatDate(data?.date) }]),
              ...(data.job
                ? [
                    {
                      title: 'Job',
                      content: 'Job ' + data.job?.id.toString(),
                      link: '/jobs/' + data.job?.id
                    }
                  ]
                : []),
              {
                title: 'Notes',
                subContent: [
                  { title: 'Company', content: data.company.notes },
                  { title: 'Contact', content: data.contact.notes },
                  { title: 'Project', content: data.project.notes },
                  ...(user?.role?.privateNotes && data.project.privateNotes?.length > 0
                    ? [{ title: 'Producer', content: data.project.privateNotes }]
                    : []),
                  ...(data.session ? [{ title: 'Session', content: data.session.notes }] : [])
                ]
              }
            ]}
          />
          <hr />
          <Patcher
            id={data.id}
            values={{ isFinished: data.isFinished.toString() }}
            url='/workorders'
            disabled={disabled || disabledManager}
          >
            <PatcherSwitch
              name='isFinished'
              displayName='Session Finished'
              tooltip={!disabled && disabledManager ? 'Cannot edit past work orders' : ''}
            />
          </Patcher>
        </Card.Body>
      </Card>

      <Services
        baseURL={baseURL}
        id={data.id}
        services={data.services}
        total={data.servicesTotal}
        mixer={data?.session?.mixer}
        initStartTime={
          data?.session?.startTime || set(new Date(data.date), { hours: 10, minutes: 0, seconds: 0 }).toISOString()
        }
        disabled={disabled || data.isFinished}
      />

      <Stock
        baseURL={baseURL}
        id={data.id}
        stock={data.stock}
        total={data.stockTotal}
        disabled={disabled || data.isFinished}
      />

      <Expenses
        baseURL={baseURL}
        id={data.id}
        expenses={data.expenses}
        total={data.expensesTotal}
        disabled={disabled || data.isFinished}
      />

      {user?.role?.managePrices && (
        <Card className='mt-3'>
          <Card.Body>
            <Card.Title>
              Totals
              <Button
                type='button'
                size='sm'
                className='float-end'
                onClick={() => modalPrice.current.openModal()}
                disabled={disabled || data.isFinished}
              >
                <PencilSquare className='icon-align' /> Edit
              </Button>
            </Card.Title>
            {data.session?.noCharge && <span className='ms-3'>** No Charge Session **</span>}
            <InfoTable
              elements={[
                { title: 'Audio Services', content: formatMoney(data.servicesTotal) },
                ...(data.unusedTime
                  ? [
                      {
                        title: 'Unused Time',
                        content:
                          data.unusedTime +
                          unusedTimeUnits +
                          ' x ' +
                          formatMoney(data.unusedRate) +
                          '/hr = ' +
                          formatMoney(data.unusedTimeTotal)
                      }
                    ]
                  : []),
                { title: 'Stock', content: formatMoney(data.stockTotal) },
                { title: 'Expenses', content: formatMoney(data.expensesTotal) },
                ...(data.priceAdjustment
                  ? [{ title: 'Price Adjustment', content: formatMoney(data.priceAdjustment) }]
                  : []),
                ...(data.adjustmentNote?.length > 0 ? [{ title: 'Notes', content: data.adjustmentNote }] : []),
                { title: 'Work Order Total', content: formatMoney(data.workOrderTotal) }
              ]}
            />
          </Card.Body>
        </Card>
      )}

      <FormButtons
        type='Work Order'
        redirectTo={redirectTo}
        showDelete={!disabled && !data.isFinished && user?.role?.manageWorkOrders}
      >
        <React.Fragment>Are you sure you want to delete this work order?</React.Fragment>
      </FormButtons>

      <ModalForm
        title='Edit Titles and Technical Data'
        size='lg'
        url={baseURL + '/info'}
        ref={modalInfo}
        putId={data.id}
        draggable
      >
        <SelectorList name='backup' list={allBackups} size='col-6' value={data?.backup} />
        <InputTextArea name='titles' displayName='Spot Titles' value={data?.titles || ''} />
        <InputTextArea name='filename' value={data?.filename || ''} />
      </ModalForm>

      <ModalForm title='Edit Work Order' url={baseURL} putId={data.id} ref={modal} draggable>
        <SelectorDate value={data?.date} valueSelect={elementSelect?.date} />
        <SelectorAPIGroup
          value={{ companyId: data?.companyId, contactId: data?.contactId, projectId: data?.projectId }}
          valueSelect={{
            companyId: elementSelect?.companyId,
            contactId: elementSelect?.contactId,
            projectId: elementSelect?.projectId
          }}
          addCompany={() => groupHelper.current.addCompany()}
          addContact={() => groupHelper.current.addContact()}
          linkContact={() => groupHelper.current.linkContact()}
          addProject={() => groupHelper.current.addProject()}
        />
      </ModalForm>

      <SelectorAPIHelper
        elementSelect={elementSelect}
        setElementSelect={setElementSelect}
        saveForm={saveForm}
        modal={modal}
        ref={groupHelper}
      />

      <ModalForm title='Edit Price' url={baseURL + '/prices'} ref={modalPrice} putId={data.id}>
        {data.unusedTime > 0 && (
          <InputNumber name='customUnusedRate' value={data.customUnusedRate?.toString()} money perHour />
        )}
        <InputNumber
          name='priceAdjustment'
          value={data.priceAdjustment?.toString()}
          negative
          money
          inactive={data.session?.noCharge}
        />
        <InputTextArea name='adjustmentNote' value={data.adjustmentNote} />
      </ModalForm>
    </React.Fragment>
  );
}
