import * as React from 'react';
import { Alert, Container, Nav, Row } from 'react-bootstrap';
import { Outlet, useMatches } from 'react-router-dom';

import { PageHeader } from '@/components/pageheader';
import { SearchBar } from '@/components/search-bar';
import { Match } from '@/helper/interfaces';
import { useStore } from '@/store';

export function Menu() {
  const { user } = useStore();

  const matches = useMatches() as Match[];
  const isList = matches.at(-1).data?.isList;

  const crumbs = matches
    .filter((match: any) => {
      return Boolean(match.handle?.crumb);
    })
    .map((match: any) => {
      return { name: match.handle?.crumb, link: match.pathname };
    });

  return (
    <Container fluid={isList ? true : false}>
      {user?.role?.invoices ? (
        <React.Fragment>
          {isList ? (
            <React.Fragment>
              <PageHeader crumbs={crumbs}>Invoices</PageHeader>
              <Nav>
                <SearchBar />
              </Nav>
            </React.Fragment>
          ) : (
            <PageHeader crumbs={crumbs}>Invoice</PageHeader>
          )}
          <Outlet />
        </React.Fragment>
      ) : (
        <Row className='justify-content-center'>
          <Alert variant='warning' className='col-6 mt-5'>
            You are not authorized to access this page
          </Alert>
        </Row>
      )}
    </Container>
  );
}
