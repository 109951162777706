/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
import * as React from 'react';
import { Button, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { getAll } from '@/helper/apicalls';
import { FormContext } from '@/helper/context';
import { useStore } from '@/store';

interface Properties {
  value?: InitSelected;
  valueSelect?: InitSelected;
  addCompany?: () => void;
  addContact?: () => void;
  linkContact?: () => void;
  addProject?: () => void;
}
interface InitSelected {
  companyId: number;
  contactId: number;
  projectId: number;
}

interface Item {
  id: number;
  name: string;
}

export function SelectorAPIGroup({ value, valueSelect, addCompany, addContact, linkContact, addProject }: Properties) {
  const { changeTracker, setWasChanged, disabled, errors } = React.useContext(FormContext);

  const { user } = useStore();

  const [companyList, setCompanyList] = React.useState([]);
  const [contactList, setContactList] = React.useState([]);
  const [projectList, setProjectList] = React.useState([]);

  const [companySelect, setCompanySelect] = React.useState([{ id: 0, name: '' }]);
  const [contactSelect, setContactSelect] = React.useState([{ id: 0, name: '' }]);
  const [projectSelect, setProjectSelect] = React.useState([{ id: 0, name: '' }]);

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    async function getdata() {
      const data = await getAll('/companies');
      setCompanyList(data);
      return data;
    }
    getdata().then((data) => {
      const companyId = valueSelect?.companyId ?? value?.companyId;
      const temporaryCompany = data.find((company: Item) => company.id === companyId);
      if (temporaryCompany) setCompanySelect([temporaryCompany]);
      setContactList(temporaryCompany?.contacts || []);
      const contactId = valueSelect?.contactId ?? value?.contactId;
      const temporaryContact = temporaryCompany?.contacts.find((contact: Item) => contact.id === contactId);
      if (temporaryContact) setContactSelect([temporaryContact]);
      setProjectList(temporaryCompany?.projects || []);
      const projectId = valueSelect?.projectId ?? value?.projectId;
      const temporaryProject = temporaryCompany?.projects.find((project: Item) => project.id === projectId);
      if (temporaryProject) setProjectSelect([temporaryProject]);
      setIsLoading(false);
    });

    const didChangeCompany = valueSelect?.companyId
      ? valueSelect?.companyId == value?.companyId
        ? false
        : true
      : false;
    const didChangeContact = valueSelect?.contactId
      ? valueSelect?.contactId == value?.contactId
        ? false
        : true
      : false;
    const didChangeProject = valueSelect?.projectId
      ? valueSelect?.projectId == value?.projectId
        ? false
        : true
      : false;
    changeTracker.current = {
      ...changeTracker.current,
      company: didChangeCompany,
      contact: didChangeContact,
      project: didChangeProject
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }, []);

  function onChangeCompany(company: any) {
    if (company[0]?.id !== companySelect[0]?.id) {
      setContactSelect([]);
      setContactList(company[0]?.contacts || []);
      setProjectSelect([]);
      setProjectList(company[0]?.projects || []);
    }
    setCompanySelect(company);

    const didChange = company[0]?.id == value?.companyId ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      company: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }
  function onChangeContact(contact: any) {
    setContactSelect(contact);

    const didChange = contact[0]?.id == value?.contactId ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      contact: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }
  function onChangeProject(project: any) {
    setProjectSelect(project);

    const didChange = project[0]?.id == value?.projectId ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      project: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }

  const showAddCompany = user?.role?.editCompanies && typeof addCompany === 'function';
  const showAddContact =
    user?.role?.editCompanies &&
    user?.role?.editContacts &&
    typeof addContact === 'function' &&
    typeof linkContact === 'function';
  const showAddProject = user?.role?.editProjects && typeof addProject === 'function';

  return (
    <React.Fragment>
      <Form.Control type='hidden' name='companyId' value={JSON.stringify(companySelect[0]?.id) || '0'} />
      <Form.Group className='mt-3 col-12'>
        <Form.Label htmlFor='company'>Company</Form.Label>
        {showAddCompany && (
          <Button variant='primary' type='button' size='sm' className='float-end btn-xs' onClick={() => addCompany()}>
            + Add
          </Button>
        )}
        <Typeahead
          id='company'
          inputProps={{ id: 'company' }}
          options={companyList}
          labelKey='name'
          onChange={onChangeCompany}
          selected={companySelect}
          isInvalid={errors?.companyId ? true : false}
          clearButton
          highlightOnlyResult={true}
          disabled={disabled || isLoading}
          placeholder={isLoading ? 'Loading...' : `--Select a Company--`}
          isLoading={isLoading}
          emptyLabel='None Found...'
        />
        <Form.Control.Feedback type='invalid'>{errors?.companyId}</Form.Control.Feedback>
      </Form.Group>

      <Form.Control type='hidden' name='contactId' value={JSON.stringify(contactSelect[0]?.id) || '0'} />
      <Form.Group className='mt-3 col-12 clearfix'>
        <Form.Label htmlFor='contact'>Contact</Form.Label>
        {showAddContact && (
          <DropdownButton
            title='+ Add'
            size='sm'
            className='float-end drop-btn-xs'
            disabled={companySelect[0]?.id === 0 ? true : false}
          >
            <Dropdown.Item onClick={() => addContact()}>Add New</Dropdown.Item>
            <Dropdown.Item onClick={() => linkContact()}>Link Existing</Dropdown.Item>
          </DropdownButton>
        )}
        <Typeahead
          id='contact'
          inputProps={{ id: 'contact' }}
          options={contactList}
          labelKey='name'
          onChange={onChangeContact}
          selected={contactSelect}
          isInvalid={errors?.contactId ? true : false}
          clearButton
          highlightOnlyResult={true}
          disabled={disabled || companySelect[0]?.id == 0 || isLoading}
          placeholder={isLoading ? 'Loading...' : `--Select a Contact--`}
          isLoading={isLoading}
          emptyLabel='None Found...'
        />
        <Form.Control.Feedback type='invalid'>{errors?.contactId}</Form.Control.Feedback>
      </Form.Group>

      <Form.Control type='hidden' name='projectId' value={JSON.stringify(projectSelect[0]?.id) || '0'} />
      <Form.Group className='mt-3 col-12'>
        <Form.Label htmlFor='project'>Project</Form.Label>
        {showAddProject && (
          <Button
            variant='primary'
            type='button'
            size='sm'
            className='float-end btn-xs'
            onClick={() => addProject()}
            disabled={companySelect[0]?.id === 0 ? true : false}
          >
            + Add
          </Button>
        )}
        <Typeahead
          id='project'
          inputProps={{ id: 'project' }}
          options={projectList}
          labelKey='name'
          onChange={onChangeProject}
          selected={projectSelect}
          isInvalid={errors?.projectId ? true : false}
          clearButton
          highlightOnlyResult={true}
          disabled={disabled || companySelect[0]?.id == 0 || isLoading}
          placeholder={isLoading ? 'Loading...' : `--Select a Project--`}
          isLoading={isLoading}
          emptyLabel='None Found...'
        />
        <Form.Control.Feedback type='invalid'>{errors?.projectId}</Form.Control.Feedback>
      </Form.Group>
    </React.Fragment>
  );
}
