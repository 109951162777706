import { parseISO, startOfDay } from 'date-fns';
import { redirect } from 'react-router-dom';

import { api } from '@/helper/api';

//import { sleeper } from './utility';

interface ListSettings {
  resultsPerPage: number;
  defaultOrderBy: string;
  defaultOrder: number;
}

export function checkIfWOExists(sessionId: number) {
  return api
    .get('/workorders/sessionexists/' + sessionId)
    .then((response) => {
      return response;
    })
    .catch(() => {
      throw new Error('An unexpected error occurred.');
    });
}

export function getAll(baseURL: string) {
  return api
    .get(baseURL)
    .then((response) => {
      return response.data.data;
    })
    .catch(() => {
      return [];
    });
}

export function getDateList(baseURL: string, parameters: URLSearchParams, settings: ListSettings) {
  const orderBy = parameters.get('o') || settings.defaultOrderBy;
  const order = parameters.get('a') || settings.defaultOrder;
  const date = parameters.get('d')
    ? startOfDay(parseISO(parameters.get('d'))).toISOString()
    : startOfDay(new Date()).toISOString();
  const query = baseURL + '?date=' + date + '&orderBy=' + orderBy + '&asc=' + order;
  const isList = true;
  return api
    .get(query)
    .then((response) => {
      const data = response.data.data;
      return { data, isList, orderBy, order };
    })
    .catch(() => {
      return { data: undefined, isList, orderBy, order };
    });
}

export function getList(url: string, parameters: URLSearchParams, settings: ListSettings) {
  const currentPage = Number.parseInt(parameters.get('p'), 10) || 1;
  const orderBy = parameters.get('o') || settings.defaultOrderBy;
  const order = parameters.get('a') || settings.defaultOrder;
  const search = parameters.get('s') || '';
  const isList = true;

  const query =
    url +
    '?take=' +
    settings.resultsPerPage +
    '&skip=' +
    settings.resultsPerPage * (currentPage - 1) +
    '&orderBy=' +
    orderBy +
    '&asc=' +
    order +
    '&search=' +
    search;
  return api
    .get(query)
    .then((response) => {
      const numberOfPages = Math.ceil(response.data.totalNumber / settings.resultsPerPage);
      const data = response.data.data;
      return { data, isList, currentPage, numberOfPages, orderBy, order };
    })
    .catch(() => {
      return { data: undefined, isList, currentPage: 1, numberOfPages: 1, orderBy, order };
    });
}

export function getSingle(url: string, id?: number) {
  return api
    .get(url + '/' + (id ?? ''))
    .then((response: any) => {
      return response.data.data;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
}

export function putSingle(url: string, id: string, body: object, redirectTo?: string) {
  return api
    .put(url + '/' + id, body)
    .then((response) => {
      return redirectTo ? redirect(redirectTo) : response;
    })
    .catch((error) => {
      return error.response.data.errors || error.response.data.message;
    });
}

export function patchSingle(url: string, id: string, body: object) {
  return api
    .patch(url + '/' + id, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data.errors || error.response.data.message;
    });
}

export function postSingle(url: string, body: object, redirectTo?: string) {
  return api
    .post(url, body)
    .then((response) => {
      return redirectTo ? redirect(redirectTo) : response;
    })
    .catch((error) => {
      return error.response.data.errors || error.response.data.message;
    });
}

export function deleteSingle(url: string, id: string, redirectTo?: string) {
  return api
    .delete(url + '/' + id)
    .then((response) => {
      return redirectTo ? redirect(redirectTo) : response;
    })
    .catch((error) => {
      return error.response.data.message;
    });
}
