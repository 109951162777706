import * as React from 'react';
import { ArrowDownShort, ArrowUpShort } from 'react-bootstrap-icons';
import { useSearchParams } from 'react-router-dom';

import { camelToTitle } from '@/helper/utility';

interface Properties {
  name: string;
  orderBy: string;
  order: number;
  children?: React.ReactNode;
}

export function Sorter({ name, orderBy, order, children }: Properties) {
  const [parameters, setParameters] = useSearchParams();
  const displayName = camelToTitle(name);

  function handleSortClick(event: React.MouseEvent<HTMLAnchorElement>, newOrderBy: string) {
    event.preventDefault();
    let newOrder = 1;
    if (newOrderBy === orderBy) {
      newOrder = order == 1 ? 0 : 1;
    }
    parameters.set('o', newOrderBy);
    parameters.set('a', String(newOrder));
    setParameters(parameters);
  }
  return (
    <th>
      <a href='#' onClick={(event) => handleSortClick(event, name)}>
        {children ?? displayName}
        {orderBy == name && order == 0 && <ArrowDownShort aria-label='sort decending' />}
        {orderBy == name && order == 1 && <ArrowUpShort aria-label='sort ascending' />}
      </a>
    </th>
  );
}
