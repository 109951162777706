import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface Properties {
  title?: string;
  size?: 'sm' | 'lg' | 'xl';
  children?: React.ReactNode;
}

export const ModalWarning = React.forwardRef(function ModalForm({ title, size, children }: Properties, reference) {
  const [modal, setModal] = React.useState(false);
  const [titleState, setTitleState] = React.useState<string>(title);
  const [warning, setWarning] = React.useState<string>();
  React.useImperativeHandle(reference, () => ({
    openModal(warning?: string, title?: string) {
      if (warning) setWarning(warning);
      if (title) setTitleState(title);
      setModal(true);
    }
  }));
  const closeModal = () => {
    setModal(false);
  };

  return (
    <Modal show={modal} onHide={closeModal} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>{titleState}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children || warning}</Modal.Body>
      <Modal.Footer>
        <Button variant='primary' type='button' className='me-2' onClick={closeModal}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
});
