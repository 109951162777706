import 'react-phone-number-input/style.css';

import * as React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input/input';

import { FormContext } from '@/helper/context';

interface Properties {
  size?: string;
  value?: string;
  inactive?: boolean;
}

export function InputPhone({ size = 'col-12', value, inactive = false }: Properties) {
  const [valueState, setValueState] = React.useState(value || '');
  const { changeTracker, setWasChanged, disabled, errors } = React.useContext(FormContext);

  function onChange(number: any) {
    setValueState(number || '');

    const didChange = number == value ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      phone: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }

  return (
    <React.Fragment>
      <Form.Control type='hidden' name='phone' value={valueState} />
      <Form.Group className={'mt-3 ' + size}>
        <Form.Label htmlFor='phoneInput'>Phone</Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            as={PhoneInput}
            defaultCountry='US'
            useNationalFormatForDefaultCountryValue={true}
            id='phoneInput'
            aria-label='phone'
            disabled={disabled || inactive}
            value={valueState}
            onChange={(number) => onChange(number)}
            isInvalid={errors.phone ? true : false}
          />
          <Form.Control.Feedback type='invalid'>{errors.phone}</Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </React.Fragment>
  );
}
