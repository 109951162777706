import * as React from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';
import { useLoaderData, useLocation } from 'react-router-dom';

import { FormButtons } from '@/components/formbuttons';
import { ContactForm } from '@/components/forms';
import { InfoTable } from '@/components/infotable';
import { InputTokens } from '@/components/input-tokens';
import { ModalForm } from '@/components/modal-form';
import { deleteSingle, getSingle, patchSingle, postSingle, putSingle } from '@/helper/apicalls';
import { Contact } from '@/helper/interfaces';
import { formatAddress, formatPhone, removeKeysWithPrefix } from '@/helper/utility';
import { useStore } from '@/store';

import { baseURL } from './const';

export async function singleLoader({ params }: any) {
  if (Number.isInteger(Number.parseInt(params.id, 10))) {
    return getSingle(baseURL, params.id);
  } else {
    throw new TypeError('404! This is not a valid URL.');
  }
}

export async function singleAction({ request, params }: any) {
  const body = Object.fromEntries(await request.formData());
  const redirectTo = body.redirectTo;
  delete body.redirectTo;

  let patchBody;
  if (body?.companyId) {
    patchBody = { companyId: JSON.parse(body.companyId) };
    delete body.companyId;
  }

  if (body.emails) body.emails = JSON.parse(body.emails);
  const bodyPruned = removeKeysWithPrefix(body, 'multiElement');

  if (request.method == 'POST') {
    if (patchBody?.companyId > 0) {
      const postResponse = await postSingle(baseURL, bodyPruned);
      if (postResponse?.data?.status === 200) {
        await patchSingle(baseURL + '/company/add', postResponse?.data?.data, patchBody);
      }
      return postResponse;
    }
    return postSingle(baseURL, bodyPruned);
  } else if (request.method == 'PUT') {
    return putSingle(baseURL, params.id, bodyPruned);
  } else if (request.method == 'DELETE') {
    return deleteSingle(baseURL, params.id, redirectTo);
  } else {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}

export function Single() {
  const { user } = useStore();
  const data = useLoaderData() as Contact;

  const location = useLocation();
  const [redirectTo] = React.useState(location.state?.from || baseURL);

  const disabled = data.deleted || !user?.role?.editContacts;

  const modal = React.useRef(null);

  return (
    <React.Fragment>
      {data.deleted && (
        <Alert variant='danger' className='mt-3'>
          This contact was deleted and can no longer be edited.
        </Alert>
      )}
      {!user?.role?.editContacts && !data.deleted && (
        <Alert variant='warning' className='mt-3'>
          You are not authorized to edit this page.
        </Alert>
      )}

      <Card>
        <Card.Body>
          <Card.Title className='clearfix'>
            Contact Info
            <Button
              type='button'
              size='sm'
              className='float-end'
              onClick={() => modal.current.openModal()}
              disabled={disabled}
            >
              <PencilSquare className='icon-align' /> Edit
            </Button>
          </Card.Title>
          <InfoTable
            elements={[
              { title: 'Name', content: data.name },
              { title: 'Title', content: data.title },
              { title: 'Email', content: data.emails.join(', ') },
              { title: 'Phone', content: formatPhone(data.phone) },
              {
                title: 'Address',
                content: formatAddress(data.address, data.city, data.state, data.zip, data.country)
              },
              { title: 'Notes', content: data.notes }
            ]}
          />
        </Card.Body>
      </Card>

      <Card className='mt-3'>
        <Card.Body>
          <Card.Title className='clearfix'>Companies</Card.Title>
          <InputTokens
            name='company'
            fetchURL={'/contacts/company/' + data.id}
            baseURL={baseURL + '/company'}
            linkURL='/companies'
            id={data.id}
            value={data.companies}
            disabled={disabled}
          />
        </Card.Body>
      </Card>

      <FormButtons type='Contact' redirectTo={redirectTo} showDelete={!disabled}>
        <React.Fragment>Are you sure you want to delete {data.name}?</React.Fragment>
      </FormButtons>

      <ModalForm title='Edit Info' size='lg' url={baseURL} putId={data.id} ref={modal} draggable>
        <ContactForm data={data} />
      </ModalForm>
    </React.Fragment>
  );
}
