import * as React from 'react';
import { Alert, Col } from 'react-bootstrap';

import { FormContext } from '@/helper/context';

import { ListLink } from './listlink';

interface Properties {
  listTitle?: string;
  children: React.ReactNode;
}

export function ApiAlert({ listTitle, children }: Properties) {
  const { errors } = React.useContext(FormContext);
  const errorIdsArray = Object.values(errors) as number[];

  return (
    <Col>
      {errorIdsArray?.length > 0 && (
        <Alert variant='danger' className='mt-3'>
          {children}
          <ul className='list-unstyled'>
            <ul>
              {errorIdsArray.map((id, index) => (
                <li key={index}>
                  <ListLink to={'/workorders/' + id} nowrapper>
                    {listTitle?.length > 0 ? listTitle + ' ' : ''}
                    {id}
                  </ListLink>
                </li>
              ))}
            </ul>
          </ul>
        </Alert>
      )}
    </Col>
  );
}
