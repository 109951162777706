/* eslint-disable security/detect-object-injection */
import * as React from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';

import { getAll } from '@/helper/apicalls';
import { FormContext } from '@/helper/context';
import { camelToTitle } from '@/helper/utility';

interface Properties {
  name: string;
  fetchURL: string;
}

export function SelectorTableMini({ name, fetchURL }: Properties) {
  const displayName = camelToTitle(name);
  const nameIds = name + 'Ids';

  const { changeTracker, setWasChanged, disabled, errors } = React.useContext(FormContext);

  const [selected, setSelected] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  async function getData(URL: string) {
    setData(await getAll(URL));
  }

  React.useEffect(() => {
    getData(fetchURL + '?jobless').then(() => {
      setIsLoading(false);
    });
  }, []);

  function handleSelect(event: React.ChangeEvent<HTMLInputElement>, id: number) {
    const temporarySelected = event.target.checked ? [...selected, id] : selected.filter((s) => s !== id);
    setSelected(temporarySelected);

    const didChange = temporarySelected.length === 0 ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      [name]: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }

  function handleSelectAll() {
    setSelected(data.map((d) => d.id));
    changeTracker.current = {
      ...changeTracker.current,
      [name]: true
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }
  function handleDeselectAll() {
    setSelected([]);
    changeTracker.current = {
      ...changeTracker.current,
      [name]: false
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }

  return (
    <React.Fragment>
      <div className='col-10 ms-auto'>{errors[nameIds] && <Alert variant='danger'>{errors[nameIds]}</Alert>}</div>

      <Form.Control type='hidden' name={nameIds} value={JSON.stringify(selected)} />
      <div className='position-relative'>
        <div id='loader' className={isLoading ? 'loading' : ''}>
          <Spinner animation='border' />
        </div>
        <div id='fader' className={isLoading || disabled ? 'loading' : ''}>
          <span className='clearfix'>
            Select {displayName}(s)
            <span className='float-end'>
              <Button variant='link' size='sm' className='btn-align me-2' onClick={handleSelectAll}>
                Select All
              </Button>
              <Button variant='link' size='sm' className='btn-align' onClick={handleDeselectAll}>
                Deselect All
              </Button>
            </span>
          </span>
          <hr />
          {data.map((d) => (
            <Form.Check
              key={d.id}
              inline
              id={'element-' + d.name}
              label={d.name}
              name={'element-' + d.name}
              type='checkbox'
              checked={selected.includes(d.id)}
              onChange={(event) => handleSelect(event, d.id)}
              disabled={disabled}
            />
          ))}
          <hr />
        </div>
      </div>
    </React.Fragment>
  );
}
