import { formatISO, parseISO } from 'date-fns';
import * as React from 'react';
import { Container, Nav } from 'react-bootstrap';
import { Outlet, useMatches, useSearchParams } from 'react-router-dom';

import { InfoTable } from '@/components/infotable';
import { InputTextArea } from '@/components/input-textarea';
import { ModalDelete } from '@/components/modal-delete';
import { ModalForm } from '@/components/modal-form';
import { ModalWarning } from '@/components/modal-warning';
import { PageHeader } from '@/components/pageheader';
import { SearchBarDate } from '@/components/search-bardate';
import { SelectorAPI } from '@/components/selector-api';
import { SelectorAPIGroup } from '@/components/selector-api-group';
import { SelectorAPIHelper } from '@/components/selector-api-helper';
import { SelectorAPISimple } from '@/components/selector-api-simple';
import { SelectorDateTime } from '@/components/selector-datetime';
import { SelectorList } from '@/components/selector-list';
import { SelectorPad } from '@/components/selector-pad';
import { Switch } from '@/components/switch';
import { Match, Session } from '@/helper/interfaces';
import { allStatuses } from '@/helper/lists';
import { useStore } from '@/store';

export function Menu() {
  const { user } = useStore();

  const matches = useMatches() as Match[];
  const isList = matches.at(-1).data?.isList;

  const crumbs = matches
    .filter((match: any) => {
      return Boolean(match.handle?.crumb);
    })
    .map((match: any) => {
      return { name: match.handle?.crumb, link: match.pathname };
    });

  const [parameters] = useSearchParams();
  const selectedDate = parameters.get('d') || formatISO(new Date());
  const selectedDateStart = formatISO(parseISO(selectedDate).setHours(10, 0, 0, 0));
  const selectedDateEnd = formatISO(parseISO(selectedDate).setHours(18, 0, 0, 0));

  const modal = React.useRef(null);
  const groupHelper = React.useRef(null);
  const modalDelete = React.useRef(null);
  const modalNotes = React.useRef(null);
  const modalWarning = React.useRef(null);
  const [session, setSession] = React.useState<Session>();
  const [sessionSelect, setSessionSelect] = React.useState<Partial<Session>>();
  function createSession() {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setSession(undefined);
    setSessionSelect({});
    modal.current.openModal();
  }
  function editSession(session: Session) {
    if (user?.role?.editSessions) {
      if (session.workOrder?.isFinished) {
        modalWarning.current.openModal('This session is finished and can no longer be edited.', 'Cannot Edit');
      } else {
        setSession(session);
        setSessionSelect({});
        modal.current.openModal();
      }
    }
  }
  function deleteSession(session: Session) {
    if (user?.role?.editSessions) {
      if (session.workOrder) {
        modalWarning.current.openModal(
          'This session has a work order and cannot be deleted. First delete WO ' + session?.workOrder?.id + '.',
          'Cannot Delete'
        );
      } else {
        setSession(session);
        modalDelete.current.openModal();
      }
    }
  }

  function showNotes(session: Session) {
    setSession(session);
    modalNotes.current.openModal();
  }

  function saveSession() {
    setSessionSelect({
      companyId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="companyId"]').value, 10),
      contactId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="contactId"]').value, 10),
      projectId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="projectId"]').value, 10),
      startTime: document.querySelector<HTMLInputElement>('input[name="startTime"]').value,
      endTime: document.querySelector<HTMLInputElement>('input[name="endTime"]').value,
      prePadHours: document.querySelector<HTMLSelectElement>('select[name="prePadHours"]').value,
      postPadHours: document.querySelector<HTMLSelectElement>('select[name="postPadHours"]').value,
      mixerId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="mixerId"]').value, 10),
      assistantId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="assistantId"]').value, 10),
      roomId: Number.parseInt(document.querySelector<HTMLSelectElement>('select[name="roomId"]').value, 10),
      status: document.querySelector<HTMLSelectElement>('select[name="status"]').value,
      notes: document.querySelector<HTMLTextAreaElement>('textarea[name="notes"]').value,
      unsupervised: document.querySelector<HTMLInputElement>('input[name="unsupervised"]').checked,
      noCharge: document.querySelector<HTMLInputElement>('input[name="noCharge"]').checked
    });
  }

  return (
    <React.Fragment>
      <Container fluid={isList ? true : false}>
        {isList ? (
          <React.Fragment>
            <PageHeader crumbs={crumbs}>Schedule</PageHeader>
            <Nav>
              {user?.role?.editSessions && <Nav.Link onClick={createSession}>Add Session</Nav.Link>}
              <SearchBarDate />
            </Nav>
          </React.Fragment>
        ) : (
          <PageHeader crumbs={crumbs}>Edit Session</PageHeader>
        )}
        <Outlet context={{ editSession, deleteSession, showNotes }} />
      </Container>

      <ModalForm
        title={(session ? 'Edit' : 'Add') + ' Session'}
        url='/schedule'
        putId={session ? session?.id : undefined}
        ref={modal}
        draggable
      >
        <SelectorAPIGroup
          value={{ companyId: session?.companyId, contactId: session?.contactId, projectId: session?.projectId }}
          valueSelect={{
            companyId: sessionSelect?.companyId,
            contactId: sessionSelect?.contactId,
            projectId: sessionSelect?.projectId
          }}
          addCompany={() => groupHelper.current.addCompany()}
          addContact={() => groupHelper.current.addContact()}
          linkContact={() => groupHelper.current.linkContact()}
          addProject={() => groupHelper.current.addProject()}
        />
        <SelectorDateTime
          value={{ startTime: session?.startTime || selectedDateStart, endTime: session?.endTime || selectedDateEnd }}
          valueSelect={{
            startTime: sessionSelect?.startTime,
            endTime: sessionSelect?.endTime
          }}
        />
        <SelectorPad name='prePadHours' value={session?.prePadHours} valueSelect={sessionSelect?.prePadHours} />
        <SelectorPad name='postPadHours' value={session?.postPadHours} valueSelect={sessionSelect?.postPadHours} />
        <SelectorAPI
          name='mixer'
          size='col-6'
          fetchURL='/users/mixers'
          value={session?.mixerId}
          valueSelect={sessionSelect?.mixerId}
        />
        <SelectorAPI
          name='assistant'
          size='col-6'
          fetchURL='/users/assistants'
          value={session?.assistantId}
          valueSelect={sessionSelect?.assistantId}
        />
        <SelectorAPISimple
          name='room'
          size='col-6'
          fetchURL='/rooms'
          value={session?.roomId}
          valueSelect={sessionSelect?.roomId}
        />
        <SelectorList
          name='status'
          list={allStatuses}
          size='col-6'
          value={session?.status}
          valueSelect={sessionSelect?.status}
        />
        <InputTextArea name='notes' value={session?.notes} valueSelect={sessionSelect?.notes} />
        <Switch name='unsupervised' value={session?.unsupervised} valueSelect={sessionSelect?.unsupervised} />
        <Switch name='noCharge' value={session?.noCharge} valueSelect={sessionSelect?.noCharge} />
      </ModalForm>

      <SelectorAPIHelper
        elementSelect={sessionSelect}
        setElementSelect={setSessionSelect}
        saveForm={saveSession}
        modal={modal}
        ref={groupHelper}
      />

      <ModalWarning title='Notes' size='lg' ref={modalNotes}>
        <InfoTable
          elements={[
            { title: 'Company', content: session?.company?.notes },
            { title: 'Contact', content: session?.contact?.notes },
            { title: 'Project', content: session?.project?.notes },
            ...(user?.role?.privateNotes && session?.project?.privateNotes?.length > 0
              ? [{ title: 'Producer', content: session?.project?.privateNotes }]
              : []),
            { title: 'Session', content: session?.notes }
          ]}
          hideEmpty
        ></InfoTable>
      </ModalWarning>

      <ModalWarning ref={modalWarning} />

      <ModalDelete title='Delete Session' url='/schedule' ref={modalDelete} deleteId={session?.id}>
        Are you sure you want to delete this session?
      </ModalDelete>
    </React.Fragment>
  );
}
