import * as React from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { PatcherContext } from '@/helper/context';
import { camelToTitle } from '@/helper/utility';

interface Properties {
  name: string;
  displayName?: string;
  tooltip?: string;
  inactive?: boolean;
}

export function PatcherSwitch({ name, displayName, tooltip = '', inactive = false }: Properties) {
  displayName = displayName || camelToTitle(name);
  const { onChange, values, disabled } = React.useContext(PatcherContext);

  return (
    <React.Fragment>
      {tooltip?.length > 0 ? (
        <OverlayTrigger overlay={<Tooltip id='managerTooltip'>{tooltip}</Tooltip>} placement='right'>
          <span>
            <Form.Check
              type='switch'
              inline
              id={name}
              name={name}
              label={displayName}
              // eslint-disable-next-line security/detect-object-injection
              checked={values[name] === 'true' ? true : false}
              onChange={onChange}
              disabled={disabled || inactive}
            />
          </span>
        </OverlayTrigger>
      ) : (
        <Form.Check
          type='switch'
          inline
          id={name}
          name={name}
          label={displayName}
          // eslint-disable-next-line security/detect-object-injection
          checked={values[name] === 'true' ? true : false}
          onChange={onChange}
          disabled={disabled || inactive}
        />
      )}
    </React.Fragment>
  );
}
