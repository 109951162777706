import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface Properties {
  to: string;
  nowrapper?: boolean;
  className?: string;
  children: React.ReactNode;
}

export function ListLink({ to, nowrapper, className, children }: Properties) {
  const location = useLocation();
  const from = location.pathname + location.search;

  return (
    <React.Fragment>
      {nowrapper ? (
        <Link to={to} state={{ from: from }} className={className}>
          {children}
        </Link>
      ) : (
        <td>
          <Link to={to} state={{ from: from }} className={className}>
            {children}
          </Link>
        </td>
      )}
    </React.Fragment>
  );
}
