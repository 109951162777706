/* eslint-disable security/detect-object-injection */
import * as React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import { FormContext } from '@/helper/context';
import { camelToTitle } from '@/helper/utility';

interface Properties {
  name: string;
  displayName?: string;
  size?: string;
  value?: string;
  setValue?: (number: number) => void;
  money?: boolean;
  perHour?: boolean;
  percentage?: boolean;
  negative?: boolean;
  inactive?: boolean;
}

export function InputNumber({
  name,
  displayName,
  size = 'col-12',
  value,
  setValue,
  money = false,
  perHour = false,
  percentage = false,
  negative = false,
  inactive = false
}: Properties) {
  displayName = displayName || camelToTitle(name);
  const [valueState, setValueState] = React.useState(value || '');
  const { changeTracker, setWasChanged, disabled, errors } = React.useContext(FormContext);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValueState(event.target.value);

    if (setValue) {
      setValue(Number.parseInt(event.target.value, 10));
    }

    const didChange = event.target.value == value ? false : true;
    changeTracker.current = {
      ...changeTracker.current,
      [event.target.name]: didChange
    };
    setWasChanged(Object.values(changeTracker.current).includes(true));
  }

  return (
    <Form.Group className={`mt-3 ${size}`}>
      <Form.Label htmlFor={name}>{displayName}</Form.Label>
      <InputGroup hasValidation>
        {money && <InputGroup.Text>&#36;</InputGroup.Text>}
        <Form.Control
          aria-label={name}
          name={name}
          id={name}
          type='number'
          disabled={disabled || inactive}
          min={negative ? '-99_999_999.99' : '0'}
          max='99_999_999.99'
          value={valueState}
          onChange={onChange}
          isInvalid={errors[name] ? true : false}
        />
        {perHour && <InputGroup.Text>Per Hour</InputGroup.Text>}
        {percentage && <InputGroup.Text>&#37;</InputGroup.Text>}
        <Form.Control.Feedback type='invalid'>{errors[name]}</Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
}
