import { putSingle } from '@/helper/apicalls';

import { baseURL } from './const';

export async function priceAction({ request, params }: any) {
  const body = Object.fromEntries(await request.formData());

  if (body?.customUnusedRate?.length === 0) delete body.customUnusedRate;
  if (body?.priceAdjustment?.length === 0) delete body.priceAdjustment;

  // eslint-disable-next-line unicorn/no-null
  return request.method == 'PUT' ? putSingle(baseURL + '/prices', params.id, body) : null;
}
