import * as React from 'react';
import { Container, Nav } from 'react-bootstrap';
import { Outlet, useMatches } from 'react-router-dom';

import { ModalForm } from '@/components/modal-form';
import { PageHeader } from '@/components/pageheader';
import { SearchBar } from '@/components/search-bar';
import { SelectorAPIGroup } from '@/components/selector-api-group';
import { SelectorAPIHelper } from '@/components/selector-api-helper';
import { SelectorDate } from '@/components/selector-date';
import { Match, WorkOrder } from '@/helper/interfaces';
import { useStore } from '@/store';

import { baseURL } from './const';

export function Menu() {
  const { user } = useStore();

  const matches = useMatches() as Match[];
  const isList = matches.at(-1).data?.isList;

  const crumbs = matches
    .filter((match: any) => {
      return Boolean(match.handle?.crumb);
    })
    .map((match: any) => {
      return { name: match.handle?.crumb, link: match.pathname };
    });

  const modal = React.useRef(null);
  const groupHelper = React.useRef(null);
  const [elementSelect, setElementSelect] = React.useState<Partial<WorkOrder>>();

  function createWorkOrder() {
    setElementSelect({});
    modal.current.openModal();
  }

  function saveForm() {
    setElementSelect({
      companyId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="companyId"]').value, 10),
      contactId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="contactId"]').value, 10),
      projectId: Number.parseInt(document.querySelector<HTMLInputElement>('input[name="projectId"]').value, 10),
      date: document.querySelector<HTMLInputElement>('input[name="date"]').value
    });
  }

  return (
    <React.Fragment>
      <Container fluid={isList ? true : false}>
        {isList ? (
          <React.Fragment>
            <PageHeader crumbs={crumbs}>Work Orders</PageHeader>
            <Nav>
              {user?.role?.manageWorkOrders && <Nav.Link onClick={createWorkOrder}>Add Work Order</Nav.Link>}
              <SearchBar />
            </Nav>
          </React.Fragment>
        ) : (
          <PageHeader crumbs={crumbs}>Edit Work Order</PageHeader>
        )}
        <Outlet />
      </Container>

      <ModalForm title='Add Work Order' url={baseURL} redirect={baseURL} ref={modal} draggable>
        <SelectorDate valueSelect={elementSelect?.date} />
        <SelectorAPIGroup
          valueSelect={{
            companyId: elementSelect?.companyId,
            contactId: elementSelect?.contactId,
            projectId: elementSelect?.projectId
          }}
          addCompany={() => groupHelper.current.addCompany()}
          addContact={() => groupHelper.current.addContact()}
          linkContact={() => groupHelper.current.linkContact()}
          addProject={() => groupHelper.current.addProject()}
        />
      </ModalForm>

      <SelectorAPIHelper
        elementSelect={elementSelect}
        setElementSelect={setElementSelect}
        saveForm={saveForm}
        modal={modal}
        ref={groupHelper}
      />
    </React.Fragment>
  );
}
